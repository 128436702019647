import axios from "axios";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import moment from "moment/moment";
let userID = localStorage.getItem("id") || "";
let userGroupId = localStorage.getItem("userGroupIds") || 0;
let userTypeAccess = localStorage.getItem("type") || "";

export const fetchUserGroups = async () => {
  try {
    let query = "";
    if (userTypeAccess == 1) {
      query = {
        query: `SELECT 'users' AS users,users.id AS users_id,users.userName FROM users UNION ALL SELECT 'userGroups' AS userGroups,userGroups.id AS group_id,userGroups.name AS userGroups_name FROM userGroups`,
      };
    } else {
      query = {
        query: `SELECT 'users' AS users,users.id AS users_id,users.userName FROM users WHERE users.createdBy = '${userID}' UNION ALL SELECT 'userGroups' AS userGroups, userGroups.id AS group_id, userGroups.name AS userGroups_name FROM userGroups WHERE userGroups.createdBy = '${userID}' `,
      };
    }

    const response = await axios.post(BASE_URL + `dynamic`, query);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const searchTables = async (searchValue) => {
  try {
    // let searchCondition = searchValue
    //   ? `WHERE files.name LIKE '%${searchValue}%'`
    //   : "";

    let masterQuery = {
      query: `SELECT files.id, files.parentFolderId, files.name, files.filePath, files.createdBy, files.lastModified, NULL as fileId, NULL as approvalStatus, NULL as uploadedDate, NULL as approvedDate, NULL as approvedBy, 'files' as type FROM files WHERE files.name LIKE '%${searchValue}%' 
              UNION 
              SELECT changeRequest.id, changeRequest.parentFolderId, changeRequest.fileName as name, changeRequest.filePath, changeRequest.createdBy, changeRequest.fileLastModified as lastModified, changeRequest.fileId, changeRequest.approvalStatus, changeRequest.uploadedDate, changeRequest.approvedDate, changeRequest.approvedBy, 'changeRequest' as type FROM changeRequest WHERE changeRequest.fileName LIKE '%${searchValue}% AND changeRequest.approvalStatus IN (1, 3)'
              UNION
              SELECT folders.id, folders.parentFolderId, folders.name, NULL as filePath, folders.createdBy, folders.lastModified, NULL as fileId, NULL as approvalStatus, NULL as uploadedDate, NULL as approvedDate, NULL as approvedBy, 'folders' as type FROM folders WHERE folders.name LIKE '%${searchValue}%'
              ORDER BY id ASC`,
    };

    let query = {
      query: `SELECT DISTINCT f.id, f.parentFolderId, f.name, f.filePath, f.createdBy, f.lastModified, NULL as fileId, NULL as approvalStatus, NULL as uploadedDate, NULL as approvedDate, NULL as approvedBy, 'files' as type FROM files as f LEFT JOIN permissions as p ON f.id = p.documentId WHERE p.documentType = 2 AND ((p.userType = 2 AND p.userId IN (${userGroupId})) OR (p.userType = 1 AND p.userId = ${userID})) AND f.name LIKE '%${searchValue}%' 
              UNION 
              SELECT DISTINCT cr.id, cr.parentFolderId, cr.fileName as name, cr.filePath, cr.createdBy, cr.fileLastModified as lastModified, cr.fileId, cr.approvalStatus, cr.uploadedDate, cr.approvedDate, cr.approvedBy, 'changeRequest' as type FROM changeRequest as cr LEFT JOIN permissions as p_cr ON cr.id = p_cr.documentId WHERE p_cr.documentType = 2 AND ((p_cr.userType = 2 AND p_cr.userId IN (${userGroupId})) OR (p_cr.userType = 1 AND p_cr.userId = ${userID})) AND cr.fileName LIKE '%${searchValue}%'
              UNION
              SELECT DISTINCT fo.id, fo.parentFolderId, fo.name, NULL as filePath, fo.createdBy, fo.lastModified, NULL as fileId, NULL as approvalStatus, NULL as uploadedDate, NULL as approvedDate, NULL as approvedBy, 'folders' as type FROM folders as fo LEFT JOIN permissions as p_fo ON fo.id = p_fo.documentId WHERE p_fo.documentType = 1 AND ((p_fo.userType = 2 AND p_fo.userId IN (${userGroupId})) OR (p_fo.userType = 1 AND p_fo.userId = ${userID})) AND fo.name LIKE '%${searchValue}%' ORDER BY id ASC`,
    };

    if (userTypeAccess == 1) {
      const response = await axios.post(BASE_URL + "dynamic", masterQuery);
      return response.data;
    } else {
      const response = await axios.post(BASE_URL + "dynamic", query);
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchFolder = async (id) => {
  try {
    let query = {
      query: `SELECT DISTINCT f.*, 'folders' as type FROM folders as f LEFT JOIN permissions as p ON f.id = p.documentId WHERE f.parentFolderId =  ${id} AND p.documentType = 1 AND ((p.userType = 2 AND p.userId IN (${userGroupId})) OR (p.userType = 1 AND p.userId = ${userID})) ORDER BY f.sequenceId ASC`,
    };

    const masterQuery = {
      query: `SELECT * , 'folders' as type FROM folders WHERE parentFolderId = ${id} ORDER BY sequenceId ASC`,
    };
    if (userTypeAccess == 1) {
      const response = await axios.post(BASE_URL + `dynamic`, masterQuery);
      return response.data;
    } else {
      const response = await axios.post(BASE_URL + `dynamic`, query);
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchNestedFolder = async (id) => {
  try {
    const masterQuery = {
      query: `SELECT * , 'folders' as type FROM folders WHERE parentFolderId = ${id} ORDER BY sequenceId ASC`,
    };
    const response = await axios.post(BASE_URL + `dynamic`, masterQuery);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchFiles = async (id) => {
  try {
    const masterQuery = {
      query: `SELECT *, 'files' as type FROM files WHERE parentFolderId = ${id} ORDER BY id ASC`,
    };
    const response = await axios.post(BASE_URL + `dynamic`, masterQuery);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchFilesPermission = async () => {
  try {
    let query = {
      query: `SELECT DISTINCT f.*, 'files' as type FROM files as f LEFT JOIN permissions as p ON f.id = p.documentId WHERE p.documentType = 2 AND ((p.userType = 2 AND p.userId IN (${userGroupId})) OR (p.userType = 1 AND p.userId = ${userID})) ORDER BY f.id ASC`,
    };

    const response = await axios.post(BASE_URL + "dynamic", query);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchFoldersPermission = async () => {
  try {
    let query = {
      query: `SELECT DISTINCT f.*, 'folders' as type FROM folders as f LEFT JOIN permissions as p ON f.id = p.documentId WHERE p.documentType = 1 AND ((p.userType = 2 AND p.userId IN (${userGroupId})) OR (p.userType = 1 AND p.userId = ${userID})) AND f.parentFolderId != 0 ORDER BY f.id ASC`,
    };

    const response = await axios.post(BASE_URL + "dynamic", query);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchchangeRequest = async (id) => {
  try {
    const masterQuery = {
      query: `SELECT * , 'changeRequest' as type FROM changeRequest WHERE parentFolderId = ${id} AND createdBy = ${userID} AND approvalStatus IN (1, 3) ORDER BY id ASC`,
    };
    const response = await axios.post(BASE_URL + `dynamic`, masterQuery);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchchangeRequestmodules = async (id) => {
  try {
    const query = {
      query: `SELECT *  FROM changeRequest ORDER BY id DESC`,
    };
    const response = await axios.post(BASE_URL + `dynamic`, query);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchViewFile = async (id, type) => {
  try {
    let query;
    if (type === "files") {
      query = {
        query: `SELECT * FROM files WHERE id = ${id}`,
      };
    } else {
      query = {
        query: `SELECT * FROM changeRequest WHERE id = ${id}`,
      };
    }

    const response = await axios.post(BASE_URL + `dynamic`, query);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const handleFileUpload = (
  event,
  parentFolderIds,
  setLoading,
  handleFolderNested,
  userID,
  nextSequenceId
) => {
  if (userTypeAccess != 1 && parentFolderIds == 0) {
    toast.error("You can't Upload File within parent folders.");
    return;
  }
  const file = event.target.files[0];

  const allowedFileTypes = [
    "application/pdf",
    "image/jpeg",
    "image/png",
    "image/gif",
  ];
  if (!allowedFileTypes.includes(file.type)) {
    toast.warning("Invalid file type. Please upload PDF or Image files.");
    return;
  }

  const data = new FormData();
  data.append("fileToUpload", file);
  setLoading(true);

  axios
    .post(BASE_URL + `fileUpload/fileUpload.php`, data)
    .then(async (res) => {
      if (res.status === 200) {
        toast.success("File Uploaded Successfully!");
        let payload = {
          fileId: 1,
          fileName: res.data.split("__").pop(),
          parentFolderId: parentFolderIds,
          filePath: res.data,
          createdBy: userID,
          approvalStatus: 1,
          approvedBy: 1,
          sequenceId: nextSequenceId,
          uploadedDate: moment(new Date()).format("YYYY-MM-DD"),
        };
        await axios.post(BASE_URL + `changeRequest`, payload);
        handleFolderNested({ id: parentFolderIds });
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error("Something Went Wrong!");
    })
    .finally(() => {
      setLoading(false);
    });
};

export const filterChangeRequestStatus = async (searchBy, setrequestData) => {
  try {
    const query = {
      query: `SELECT * FROM changeRequest WHERE approvalStatus = '${searchBy}' ORDER BY id ASC`,
    };
    const response = await axios.post(BASE_URL + "dynamic", query);
    setrequestData(response.data);
  } catch (error) {
    console.error(error);
  }
};

export const fetchUsers = async (id) => {
  try {
    const query = {
      query: `SELECT * FROM users`,
    };
    const response = await axios.post(BASE_URL + `dynamic`, query);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchPermissions = async (searchKey) => {
  try {
    let searchCondition = "";
    if (searchKey) {
      searchCondition = ` AND ((p.userType = 1 AND u.userName LIKE '%${searchKey}%') OR (p.userType = 2 AND ug.name LIKE '%${searchKey}%'))`;
    }

    const query = {
      query: `SELECT p.id, p.documentId, p.documentType, p.userType, p.userId, CASE WHEN p.userType = 1 THEN u.userName WHEN p.userType = 2 THEN ug.name END AS userGroupName FROM permissions p LEFT JOIN users u ON p.userId = u.id AND p.userType = 1 LEFT JOIN userGroups ug ON p.userId = ug.id AND p.userType = 2 WHERE 1 ${searchCondition} ORDER BY p.id DESC`,
    };

    const response = await axios.post(BASE_URL + "dynamic", query);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchDerivedPermissions = async (parent, searchKey) => {
  try {
    let searchCondition = "";
    if (searchKey) {
      searchCondition = ` AND ((p.userType = 2 AND ug.name LIKE '%${searchKey}%') OR (p.userType = 1 AND u.userName LIKE '%${searchKey}%'))`;
    }

    const query = {
      query: `SELECT p.id, p.documentId, p.documentType, p.userType, p.userId, CASE WHEN p.userType = 1 THEN u.userName WHEN p.userType = 2 THEN ug.name END AS userGroupName FROM permissions p LEFT JOIN users u ON p.userId = u.id AND p.userType = 1 LEFT JOIN userGroups ug ON p.userId = ug.id AND p.userType = 2  WHERE p.documentType = 1 AND p.documentId IN (${
        parent || 0
      }) ${searchCondition} ORDER BY p.id DESC`,
    };

    const response = await axios.post(BASE_URL + `dynamic`, query);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchDepartments = async () => {
  try {
    const response = await axios.get(BASE_URL + `departments`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
