import React from "react";
import "./user.css";
import {
  Autocomplete,
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import InfoIcon from "@mui/icons-material/Info";

const axios = require("axios");

export const userTypes = [
  {
    id: 1,
    label: "Admin",
    accesslevel: 1,
  },
  {
    id: 2,
    label: "Branch Admin",
    accesslevel: 1,
  },
  {
    id: 3,
    label: "Spartan Viewer",
    accesslevel: 2,
  },
];
const moduleList = [
  {
    id: 1,
    name: "dashboard",
    accesslevel: 2,
  },
  {
    id: 2,
    name: "filemanager",
    accesslevel: 2,
  },
  {
    id: 3,
    name: "users",
    accesslevel: 1,
  },
  {
    id: 4,
    name: "usergroups",
    accesslevel: 1,
  },
  {
    id: 5,
    name: "request",
    accesslevel: 1,
  },
  {
    id: 6,
    name: "Audit",
    accesslevel: 2,
  },
  {
    id: 7,
    name: "Master",
    accesslevel: 1,
  },
];

const userActions = [
  {
    id: 1,
    name: "folder create",
    accesslevel: 2,
  },
  {
    id: 2,
    name: "file upload",
    accesslevel: 2,
  },
  {
    id: 3,
    name: "edit",
    accesslevel: 2,
  },
  {
    id: 4,
    name: "delete",
    accesslevel: 1,
  },
  {
    id: 5,
    name: "permission",
    accesslevel: 1,
  },  
];

export default function EditUserMaster() {
  const { id } = useParams();
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passVal, setPasswordVal] = React.useState([0, 0, 0, 0, 0, 0]);
  const [roles, setRoles] = React.useState("");
  const [executing, setExecuting] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [modules, setModules] = React.useState(null);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [type, setType] = React.useState("");
  const [action, setAction] = React.useState(null);
  const [selectedAction, setSelectedAction] = React.useState([]);
  const [groups, setGroups] = React.useState(null);
  const [selectedGroups, setSelectedGroups] = React.useState([]);
  // console.log("Roles", roles, "type", type);
  const userID = localStorage.getItem("id");
  const userType = localStorage.getItem("type");
  const userGroupIds = localStorage.getItem("userGroupIds");
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const initModules = () => {
    setModules(moduleList);
    setAction(
      userType == 2
        ? userActions?.filter((module) => module?.accesslevel === 2)
        : userActions
    );
    axios
      .get(BASE_URL + `userGroups`)
      .then((res) => {
        const filteredGroups =
          userType == 1
            ? res?.data
            : res?.data?.filter((group) => group.createdBy == userID);
        setGroups(filteredGroups);
      })
      .catch((err) => console.log(err));
  };
  var refresh = () => {
    window.location.reload(false);
  };

  const onSubmit = async () => {
    setExecuting(true);
    var jsonData = {
      userName: userName,
      password: password,
      createdBy: userID || null,
      modules:
        roles?.id !== 1
          ? (selectedModules || [])?.map((m) => m.id).join(",")
          : null,
      type: type?.id,
      actions: selectedAction?.map((e) => e.id).join(","),
      userGroupIds: selectedGroups?.map((e) => e.id).join(","),
    };
    // console.log(jsonData);

    let ret = id
      ? axios.patch(BASE_URL + `users/${id}`, jsonData)
      : axios.post(BASE_URL + `users`, jsonData);
    ret
      .then((res) => {
        id
          ? toast.success("Updated Sucessfully!")
          : toast.success("User Create Sucessfully!");
          refresh();
          setExecuting(false);
      })
      .catch((error) => {
        if(error?.response?.data?.error?.code == "ER_DUP_ENTRY"){
          toast.error("User Already Exist!");
        }else{
          toast.error("Something Went Wrong!");
        }
        setExecuting(false);
      });
  };

  async function fetchUserDetails() {
    await axios.get(BASE_URL + `users/${id}`).then((res) => {
      setUserName(res.data[0].userName);
      setPassword(res.data[0].password);
      setType({ id: res.data[0].type });
      if (res.data[0].password) {
        let vals = passVal;

        let lowerCaseLetters = /[a-z]/g;
        res.data[0].password.match(lowerCaseLetters)
          ? (vals[0] = 1)
          : (vals[0] = 0);

        // Validate capital letters
        let upperCaseLetters = /[A-Z]/g;
        res.data[0].password.match(upperCaseLetters)
          ? (vals[1] = 1)
          : (vals[1] = 0);

        // Validate numbers
        let numbers = /[0-9]/g;
        res.data[0].password.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

        // Validate length
        res.data[0].password.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
        res.data[0].password.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

        setPasswordVal(vals);
      }
      res.data[0].modules
        ? setSelectedModules(
            res.data[0].modules?.split(",").map((m) => moduleList[m - 1] || [])
          )
        : setSelectedModules([]);
      res.data[0].actions
        ? setSelectedAction(
            res.data[0].actions?.split(",").map((m) => userActions[m - 1] || [])
          )
        : setSelectedAction([]);
      if (groups) {
        res?.data[0]?.userGroupIds
          ? setSelectedGroups(
              res.data[0]?.userGroupIds
                ?.split(",")
                .map(
                  (groupId) =>
                    groups.find(
                      (group) => group.id === parseInt(groupId, 10)
                    ) || null
                )
                .filter((group) => group !== null)
            )
          : setSelectedGroups([]);
      }
    });
  }

  React.useEffect(() => {
    initModules();
  }, []);
  React.useEffect(() => {
    if (id && groups) {
      fetchUserDetails();
    }
  }, [id, groups]);

  return (
    <Paper sx={{ px: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        {id
          ? `Edit ${roles?.label ? roles?.label : ""} : ${userName}`
          : "Add User"}
      </Typography>
      <Grid container spacing={2} mb={5}>
        <Grid item xs={12} md={3}>
          <TextField
            id="outlined-basic"
            label="Username *"
            value={userName}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => setUserName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            id="outlined-basic"
            label="Password *"
            value={password}
            size="small"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              let vals = passVal;

              let lowerCaseLetters = /[a-z]/g;
              e.target.value.match(lowerCaseLetters)
                ? (vals[0] = 1)
                : (vals[0] = 0);

              // Validate capital letters
              let upperCaseLetters = /[A-Z]/g;
              e.target.value.match(upperCaseLetters)
                ? (vals[1] = 1)
                : (vals[1] = 0);

              // Validate numbers
              let numbers = /[0-9]/g;
              e.target.value.match(numbers) ? (vals[2] = 1) : (vals[2] = 0);

              // Validate length
              e.target.value.length >= 8 ? (vals[3] = 1) : (vals[3] = 0);
              e.target.value.length <= 32 ? (vals[4] = 1) : (vals[4] = 0);

              setPasswordVal(vals);
              setPassword(e.target.value);
            }}
            style={{ position: "relative" }}
          />
          <InfoIcon
            style={{ color: "gray", position: "absolute", height: "20px" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          {isHovered && (
            <div>
              <p id="letter" className={passVal[0] ? "valid" : "invalid"}>
                A <b>lowercase</b> letter
              </p>
              <p id="capital" className={passVal[1] ? "valid" : "invalid"}>
                A <b>capital (uppercase)</b> letter
              </p>
              <p id="number" className={passVal[2] ? "valid" : "invalid"}>
                A <b>number</b>
              </p>
              <p id="lengthMin" className={passVal[3] ? "valid" : "invalid"}>
                Minimum <b>8 characters</b>
              </p>
              <p id="lengthMax" className={passVal[4] ? "valid" : "invalid"}>
                Maximum <b>32 characters</b>
              </p>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {!id && (
          <Grid item xs={12} md={3}>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              // options={userTypes}
              options={userTypes.filter(
                (item) => userType === "1" || item.accesslevel == 2
              )}
              value={type}
              renderInput={(params) => (
                <TextField {...params} label="User Types *" />
              )}
              onChange={(e, val) => {
                setType(val);
              }}
            />
          </Grid>
        )}
        {type?.id !== 1 && (
          <Grid item xs={12} md={3}>
            <Autocomplete
              multiple
              fullWidth
              id="modules"
              size="small"
              options={
                userType === "2"
                  ? moduleList.filter((module) => module.accesslevel == 2)
                  : moduleList || []
              }
              defaultValue={undefined}
              value={selectedModules || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />

                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select modules (optional)"
                  placeholder="select multiple modules"
                />
              )}
              onChange={(event, value) => setSelectedModules(value || [])}
            />
          </Grid>
        )}
        {type?.id !== 1 && (
          <Grid item xs={12} md={3}>
            <Autocomplete
              multiple
              fullWidth
              id="action"
              size="small"
              options={action || []}
              defaultValue={undefined}
              value={selectedAction || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />

                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User Action"
                  placeholder="select user action"
                />
              )}
              onChange={(event, value) => setSelectedAction(value || [])}
            />
          </Grid>
        )}
        {type?.id !== 1 && (
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              fullWidth
              id="groups"
              size="small"
              options={groups || []}
              defaultValue={undefined}
              value={selectedGroups || []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name || ""}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />

                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User Group"
                  placeholder="select user Group"
                />
              )}
              onChange={(event, value) => setSelectedGroups(value || [])}
            />
          </Grid>
        )}
      </Grid>
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          style={{ backgroundColor: "grey" }}
          variant="contained"
          size="small"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 2 }}
          disabled={
            executing ||
            userName === "" ||
            password === "" ||
            passVal.reduce((s, v) => s + v, 0) < 5 ||
            (!id && !type?.label)
          }
          onClick={() => {
            onSubmit();
          }}
        >
          {`${id ? "Update" : "Create"} ${
            type && type?.label ? `${type?.label} : ` : ""
          } ${userName}`}
        </Button>
      </Toolbar>
    </Paper>
  );
}
