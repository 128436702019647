import { styled, useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../image/logo.png";
import BadgeIcon from "@mui/icons-material/Badge";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ReceiptIcon from "@mui/icons-material/Receipt";
import MenuIcon from "@mui/icons-material/Menu";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import {
  Badge,
  Box,
  InputAdornment,
  ListItem,
  Menu,
  MenuItem,
  Modal,
  Popover,
  SwipeableDrawer,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Toolbar from "@mui/material/Toolbar";
import BuildIcon from "@mui/icons-material/Build";
import Typography from "@mui/material/Typography";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "./../../global";
import axios from "axios";
import { toast } from "react-toastify";
import HeaderImg from "../image/header.jpg";

export const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Navbar(props) {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [department, setDepartment] = React.useState(false);
  const [Audit, setAudit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [resposiveAnchor, setResposiveAnchor] = React.useState({
    left: false,
  });
  const [userData, setUserData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordVisibility, setPasswordVisibility] = useState({
    password: false,
    confirmPassword: false,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const open3 = Boolean(anchorEl2);
  const location = useLocation();
  const pathname = location.pathname;
  const routeSegments = pathname.split("/");
  let selectedRoute = routeSegments[1];
  if (routeSegments[1] === "master" && routeSegments.length > 1) {
    selectedRoute = routeSegments[2];
  }
  const convertToTitleCase = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevVisibility) => ({
      ...prevVisibility,
      [field]: !prevVisibility[field],
    }));
  };
  const handleModalOpen = () => {
    setIsModalOpen(true);
    handlePopoverClose();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const selectedRouteInUpperCase = convertToTitleCase(selectedRoute);
  const storage_userName = localStorage.getItem("userName");
  const storage_userID = localStorage.getItem("id");
  const storage_userType = localStorage.getItem("type");
  const storage_modules = localStorage
    .getItem("modules")
    ?.split(",")
    .map((e) => e)
    .map((e) => Number(e));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    props.setIsLoggedIn(false);
    window.localStorage.clear();
    navigate("/");
    window.location.reload(false);
  };

  const hasAccessToModule = (key) => {
    key = Number(key);
    if (storage_modules?.includes(key)) return true;
    else if (storage_userType === "1") return true;
    else return false;
  };

  const onIdle = () => {
    logout();
  };

  const onActive = () => {};

  const onAction = () => {};

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 15 * 60 * 1000,
    throttle: 250,
    crossTab: true,
  });

  const [state, setState] = React.useState({
    left: false, // Only left is kept
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const sidebarListItems = () => (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {hasAccessToModule("1") && (
        <ListItemButton
          component={Link}
          to="/dashboard"
          onClick={() => {
            if (pathname == "/dashboard") {
              window.location.reload();
            }
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Dashboard"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"Dashboard"}
            </Typography>
          )}
        </ListItemButton>
      )}
      {hasAccessToModule("7") && (
        <ListItemButton onClick={(e) => setDepartment(!department)}>
          <ListItemIcon>
            <Tooltip title="Master" arrow placement="right">
              <FormatListBulletedIcon />
            </Tooltip>
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Master"} />
          ) : (
            <ListItemText>
              <Typography
                variant="p"
                component="div"
                align="left"
                style={{ fontSize: "14px" }}
              >
                {"Master"}
              </Typography>
            </ListItemText>
          )}
          {department ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={department} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            component={Link}
            to="/department"
            onClick={() => {
              if (pathname == "/department") {
                window.location.reload();
              }
            }}
          >
            <ListItemIcon>
              <Tooltip title="Department" arrow placement="right">
                <BuildIcon />
              </Tooltip>
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Department"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Department"}
              </Typography>
            )}
          </ListItemButton>
        </List>
      </Collapse>
      {hasAccessToModule("2") && (
        <ListItemButton
          component={Link}
          to="/filemanager"
          onClick={() => {
            if (pathname == "/filemanager") {
              window.location.reload();
            }
          }}
        >
          <ListItemIcon>
            <FolderCopyIcon />
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"File Manager"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"File Manager"}
            </Typography>
          )}
        </ListItemButton>
      )}
      {hasAccessToModule("3") && (
        <ListItemButton
          component={Link}
          to="/users"
          onClick={() => {
            if (pathname == "/users") {
              window.location.reload();
            }
          }}
        >
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Users"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"Users"}
            </Typography>
          )}
        </ListItemButton>
      )}
      {hasAccessToModule("4") && (
        <ListItemButton
          component={Link}
          to="/usergroups"
          onClick={() => {
            if (pathname == "/usergroups") {
              window.location.reload();
            }
          }}
        >
          <ListItemIcon>
            <GroupAddIcon />
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"User Groups"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"User Groups"}
            </Typography>
          )}
        </ListItemButton>
      )}
      {hasAccessToModule("5") && (
        <ListItemButton
          component={Link}
          to="/request"
          onClick={() => {
            if (pathname == "/request") {
              window.location.reload();
            }
          }}
        >
          <ListItemIcon>
            <BadgeIcon />
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Request"} />
          ) : (
            <Typography variant="p" component="div" align="left">
              {"Request"}
            </Typography>
          )}
        </ListItemButton>
      )}

      {hasAccessToModule("6") && (
        <ListItemButton onClick={(e) => setAudit(!Audit)}>
          <ListItemIcon>
            <FormatListBulletedIcon />
          </ListItemIcon>
          {!isMobile ? (
            <ListItemText primary={"Audit"} />
          ) : (
            <ListItemText>
              <Typography
                variant="p"
                component="div"
                align="left"
                style={{ fontSize: "14px" }}
              >
                {"Audit"}
              </Typography>
            </ListItemText>
          )}
          {Audit ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      )}
      <Collapse in={Audit} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 3 }}
            component={Link}
            to="/internal_Audit_List"
            onClick={() => {
              if (pathname == "/internal_Audit_List") {
                window.location.reload();
              }
            }}
          >
            <ListItemIcon>
              <RequestQuoteIcon />
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Audit Correct Action"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Audit Correct Action"}
              </Typography>
            )}
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 3 }}
            component={Link}
            to="/internal_Observation_List"
            onClick={() => {
              if (pathname == "/internal_Observation_List") {
                window.location.reload();
              }
            }}
          >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            {!isMobile ? (
              <ListItemText primary={"Audit Observation"} />
            ) : (
              <Typography variant="p" component="div" align="left">
                {"Audit Observation"}
              </Typography>
            )}
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );

  const MobileList = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <DrawerHeader>
        <Typography variant="h6" gutterBottom component="div">
          Calibration
        </Typography>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      {sidebarListItems()}
    </Box>
  );

  const handleDrawerToggle = () => {
    if (isMobile) {
      setResposiveAnchor({ ...resposiveAnchor, left: !resposiveAnchor.left });
    } else {
      // handleDrawerOpen();
      setOpen(!open);
    }
  };

  React.useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handleTooltipClick = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const isPopoverOpen = Boolean(popoverAnchorEl);
  const popoverId = isPopoverOpen ? "user-profile-popover" : undefined;

  const passwordChangeModal = () => {
    return (
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="edit-profile-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 550,
            "@media (max-width: 600px)": {
              width: "80%", // Adjust the width for screens up to 768px wide
            },
            "@media (max-width: 480px)": {
              width: "90%", // Adjust the width for screens up to 480px wide
            },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 2,
            maxHeight: "550px",
            overflow: "auto",
            padding: "30px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Edit Password
          </Typography>
          <TextField
            disabled
            value={userData.username || ""}
            onChange={handleInputChange("username")}
            fullWidth
            margin="normal"
          />
          <TextField
            label="New Password"
            type={passwordVisibility.password ? "text" : "password"}
            value={userData.password}
            onChange={handleInputChange("password")}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => togglePasswordVisibility("password")}
                  >
                    {passwordVisibility.password ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            type={passwordVisibility.confirmPassword ? "text" : "password"}
            value={userData.confirmPassword}
            onChange={handleInputChange("confirmPassword")}
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                  >
                    {passwordVisibility.confirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveChanges}
          >
            Save Changes
          </Button>
        </Box>
      </Modal>
    );
  };
  const handleInputChange = (field) => (event) => {
    setUserData({ ...userData, [field]: event.target.value });
  };

  const handleSaveChanges = async () => {
    if (userData.password !== userData.confirmPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }
    try {
      const response = await axios.patch(
        BASE_URL + `/users/${storage_userID}`,
        {
          password: userData.password,
        }
      );

      if (response.status === 200) {
        toast.success("Password updated successfully");
        handleModalClose();
      } else {
        console.error("Failed to update password");
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
    handlePopoverClose();
  };

  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(BASE_URL + `/users/${storage_userID}`);

        if (response.status === 200) {
          const userData = response.data;
          setUserData({
            username: userData[0].userName,
          });
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      {passwordChangeModal()}
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          variant="dense"
          style={{ paddingRight: "5px", justifyContent: "space-between" }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            // onClick={toggleDrawer("left", true)}
            // onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "5px",
              ...(!isMobile && open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="p"
            component="div"
            // sx={{ flexGrow: 1 }}
            align="left"
          >
            {selectedRouteInUpperCase}
          </Typography>
          <div
            style={{
              backgroundColor: "white",
              margin: "10px 0px",
              borderRadius: "15px",
              padding: "0px 7px",
            }}
          >
            <img
              id="headernavbarimg"
              src={`${HeaderImg}`}
              alt="HeaderImg"
              style={{ width: "100%", height: "100px", margin: "auto" }}
            />
          </div>

          <Tooltip
            style={{
              textTransform: "uppercase",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              size="small"
              style={{ backgroundColor: "white", marginRight: "5px" }}
              disabled
            >
              <AccountCircleIcon style={{ color: "#1976d2" }} />
            </IconButton>
            <Typography
              variant="p"
              component="div"
              align="left"
              onClick={handleTooltipClick}
              sx={{ cursor: "pointer", mr: 3 }}
            >
              {storage_userName}
            </Typography>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {!isMobile ? (
        <Drawer variant="permanent" open={open}>
          <DrawerHeader sx={{ height: "100px" }}>
            <img
              src={`${Logo}`}
              alt="logo"
              style={{
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                height: "100%",
                width: "100%",
                margin: "auto",
                textAlign: "center",
              }}
            />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          {sidebarListItems()}
        </Drawer>
      ) : null}

      <SwipeableDrawer
        anchor={"left"}
        open={resposiveAnchor["left"]}
        onClose={toggleDrawer("left", false)}
        onOpen={toggleDrawer("left", true)}
      >
        {MobileList("left")}
      </SwipeableDrawer>

      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem>
            <Button onClick={handleModalOpen}>
              <RestartAltIcon style={{ marginRight: "10px" }} />
              Reset Password
            </Button>
          </ListItem>
          <ListItem>
            <Button onClick={logout}>
              <LogoutIcon style={{ marginRight: "10px" }} />
              Logout
            </Button>
          </ListItem>
        </List>
      </Popover>
    </>
  );
}
