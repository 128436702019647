import React, { useState } from "react";
import "./components/fileManager/style.css";
import { CircularProgress } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";

import Main from "./components/home/main";
import { useEffect } from "react";
// import LoginForm from "./../login/Login";

function App() {
  const [loader, setLoader] = useState(undefined);
  useEffect(() => {
    if (loader == false) {
      window.location.reload(true);
    }
  }, [loader]);

  useEffect(() => {
    // document.addEventListener("keyup", (e) => {
    //   if (e.key == "PrintScreen") {
    //     navigator.clipboard.writeText("");
    //   }
    // });
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);

  return (
    <div className="App">
      {loader && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            background: "rgba(255,255,255, 0.85)",
            zIndex: "2",
          }}
        >
          <CircularProgress style={{ width: "70px", height: "70px" }} />
        </div>
      )}
      <Main setLoader={setLoader} />
      <footer id="footerScroll">
        <span>
          <CallIcon style={{ fontSize: "12px" }} /> Pravin Kashid, +91 97698
          47865
        </span>
        <span>
          Copyright © 2022 All Right Reserved By&nbsp; <b> BFI</b>
        </span>
        <span>
          <EmailIcon style={{ fontSize: "12px" }} /> Info@bfiinfotech.co.in
        </span>
      </footer>
    </div>
  );
}
export default App;
