import React from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Box, Button, IconButton } from "@mui/material";
import FooterBack from "../image/pdfbottom.png";
import { fetchViewFile } from "./fetchHandler";
import { useParams } from "react-router";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
// import packageJson from "../../../package.json";
// const getPdfJsVersion = () => packageJson.dependencies["pdfjs-dist"];

const footerStyle = {
  width: "95%",
  margin: "auto",
  height: "100px",
  backgroundImage: `url(${FooterBack})`,
  backgroundSize: "cover",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const ViewFile = (props) => {
  const [urlLink, seturlLink] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  React.useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const { id, type } = useParams();
  const fetchData = async () => {
    try {
      const fetchfile = await fetchViewFile(
        id || props.fileId,
        type || props.fileType
      );
      seturlLink(fetchfile[0]?.filePath);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // const pdfJsVersion = getPdfJsVersion();
  // const workerUrl = `/pdf.js`;

  const isPdf = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    return extension === "pdf";
  };
  const renderFile = (url) => {
    if (loading) {
      return <p>Loading...</p>;
    } else if (url) {
      return isPdf(url) ? (
        <Worker workerUrl={`/pdf.js`}>
          <Viewer fileUrl={url} />
        </Worker>
      ) : (
        <div style={{ position: "relative", width: "100%", margin: "auto" }}>
          <img src={url} alt="image" style={{ maxWidth: "100%" }} />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              opacity: 0.4,
              fontSize: "5rem",
              color: "white",
              fontFamily: "Arial, sans-serif",
              fontWeight: "bold",
            }}
          >
            Spartan Engineering Industries PVT.LTD.
          </div>
        </div>
      );
    } else {
      return <p>No file available.</p>;
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box style={{ width: "100%", margin: "10px auto", paddingBottom: "10px" }}>
      <Box sx={{ display: "flex", justifyContent: "flex-end", m: 2 }}>
        <Button
          variant="contained"
          size="small"
          onClick={props.onClose ? props.onClose : () => window.history.back()}
          >
          Back
        </Button>
      </Box>

      <header
        style={{
          backgroundColor: "#F2F2F2",
          height: "auto",
          width: "100%",
          // padding: "30px 10px",
        }}
      ></header>
      <div style={{ margin: "10px" }}>
        <div style={{ margin: "10px" }}>{renderFile(urlLink)}</div>
      </div>

      <footer style={footerStyle}></footer>
      <IconButton
        color="success"
        variant="contained"
        size="small"
        onClick={scrollToTop}
        style={{
          display: isVisible ? "block" : "none",
          position: "fixed",
          bottom: "20px",
          right: "5px",
        }}
      >
        <ArrowCircleUpRoundedIcon style={{ fontSize: "1.8rem" }} />
      </IconButton>
    </Box>
  );
};

export default ViewFile;
