import axios from "axios";
import React from "react";
import { BASE_URL } from "../../global";

export default function Dashboard() {
  const [approveCount, setApproveCount] = React.useState(0);
  const [rejectCount, setRejectCount] = React.useState(0);
  const [fileCount, setFileCount] = React.useState(0);
  const [totalfileCount, setTotalFileCount] = React.useState(0);
  const [folderCount, setFolderCount] = React.useState(0);
  const [totalfolderCount, setTotalFolderCount] = React.useState(0);
  const [totalaudit, setAudit] = React.useState(0);
  const [totalauditobse, setAduitObse] = React.useState(0);
  const fetchCountData = async () => {
    try {
      const queryApprove = {
        query: `SELECT COUNT(*) AS approvalStatus FROM changeRequest WHERE approvalStatus = 2`,
      };
      const responseApprove = await axios.post(
        BASE_URL + `dynamic`,
        queryApprove
      );
      setApproveCount(responseApprove.data[0]?.approvalStatus);

      const queryReject = {
        query: `SELECT COUNT(*) AS approvalStatus FROM changeRequest  WHERE approvalStatus = 3`,
      };
      const responseReject = await axios.post(
        BASE_URL + `dynamic`,
        queryReject
      );
      setRejectCount(responseReject.data[0]?.approvalStatus);
      const queryFile = {
        query: `SELECT COUNT(*) AS todayUploadsCount FROM changeRequest WHERE DATE(fileLastModified) = CURRENT_DATE`,
      };
      const responseFile = await axios.post(BASE_URL + `dynamic`, queryFile);
      setFileCount(responseFile.data[0]?.todayUploadsCount);

      const queryFileTotal = {
        query: `SELECT COUNT(*) AS totalCount FROM changeRequest`,
      };
      const responseFileTotal = await axios.post(BASE_URL + `dynamic`, queryFileTotal);
      setTotalFileCount(responseFileTotal.data[0]?.totalCount);


      const queryFolder = {
        query: `SELECT COUNT(*) AS newFoldersCount FROM folders WHERE DATE(lastModified) = CURRENT_DATE`,
      };
      const responseFolder = await axios.post(
        BASE_URL + `dynamic`,
        queryFolder
      );
      setFolderCount(responseFolder.data[0]?.newFoldersCount);

      const queryFolderTotal = {
        query: `SELECT COUNT(*) AS newFoldersCount FROM folders`,
      };
      const responseFolderTotal = await axios.post(
        BASE_URL + `dynamic`,
        queryFolderTotal
      );
      setTotalFolderCount(responseFolderTotal.data[0]?.newFoldersCount);

      const auditTotalObser = {
        query: `SELECT COUNT(*) AS auditCount FROM internalAuditObservation`,
      };
      const auditObserresponseTotal = await axios.post(
        BASE_URL + `dynamic`,
        auditTotalObser
      );
      setAduitObse(auditObserresponseTotal.data[0]?.auditCount);

      const auditTotal = {
        query: `SELECT COUNT(*) AS auditCount FROM internalAuditCorrectiveActions`,
      };
      const auditresponseTotal = await axios.post(
        BASE_URL + `dynamic`,
        auditTotal
      );
      setAudit(auditresponseTotal.data[0]?.auditCount);

    } catch (error) {
      console.error(error);
    }
  };
  React.useEffect(() => {
    fetchCountData();
  }, []);
  return (
    <div>
      <div className="container-fluid mt-5">
        <div className="row  h-50">
          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="dashboardcard bg-c-green order-dashboardcard">
              <div className="dashboardcard-block">
                <h6 className="m-b-20">Total File Upload</h6>
                <h2 className="text-center">
                  <span className="h3">{totalfileCount || 0}</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="dashboardcard bg-c-yellow order-dashboardcard">
              <div className="dashboardcard-block">
                <h6 className="m-b-20">Total Folder Create</h6>
                <h2 className="text-center">
                  <i className="fa fa-refresh f-left"></i>
                  <span className="h3">{totalfolderCount || 0}</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="dashboardcard bg-c-blue order-dashboardcard">
              <div className="dashboardcard-block">
                <h6 className="m-b-20">Total Audit Observation</h6>
                <h2 className="text-center">
                  <span className="h3">{totalauditobse || 0}</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="dashboardcard bg-c-pink order-dashboardcard">
              <div className="dashboardcard-block">
                <h6 className="m-b-20">Total Audit</h6>
                <h2 className="text-center">
                  <span className="h3">{totalaudit || 0}</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="dashboardcard bg-c-green order-dashboardcard">
              <div className="dashboardcard-block">
                <h6 className="m-b-20">Today File Upload</h6>
                <h2 className="text-center">
                  <span className="h3">{fileCount || 0}</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="dashboardcard bg-c-yellow order-dashboardcard">
              <div className="dashboardcard-block">
                <h6 className="m-b-20">Today Folder Create</h6>
                <h2 className="text-center">
                  <i className="fa fa-refresh f-left"></i>
                  <span className="h3">{folderCount || 0}</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="dashboardcard bg-c-blue order-dashboardcard">
              <div className="dashboardcard-block">
                <h6 className="m-b-20">Total Files Approved</h6>
                <h2 className="text-center">
                  <span className="h3">{approveCount || 0}</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-md-4 col-xl-3">
            <div className="dashboardcard bg-c-pink order-dashboardcard">
              <div className="dashboardcard-block">
                <h6 className="m-b-20">Total Files Reject</h6>
                <h2 className="text-center">
                  <span className="h3">{rejectCount || 0}</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
