import {
  Box,
  Button,
  Grid,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { BASE_URL } from "../../global";
import axios from "axios";
import { toast } from "react-toastify";

export default function EditDepartment() {
  const { id } = useParams();
  const [departmentName, setDepartmentName] = React.useState("");

  const onSubmit = () => {
    var jsonData = {
      name: departmentName,
    };
    let url = BASE_URL;
    (() =>
      id != 0
        ? axios.patch(url + `departments/${id}`, jsonData)
        : axios.post(url + "departments", jsonData))()
      .then((res) => {
        id != 0 ? toast("Department Update!") : toast("New Department Added!");
        setTimeout(refresh, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDepartmentName = () => {
    let url = BASE_URL;
    axios
      .get(url + `departments/${id}`)
      .then((res) => {
        setDepartmentName(res.data[0].name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const refresh = () => {
    window.location.reload(false);
  };

  React.useEffect(() => {
    if (id != 0) {
      getDepartmentName();
    }
  }, []);

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        {id != 0 ? "Edit" : "Create"}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <TextField
            label="Department name *"
            size="small"
            fullWidth
            value={departmentName || ""}
            variant="outlined"
            onChange={(e) => setDepartmentName(e.target.value)}
          />
        </Grid>
      </Grid>
      <Toolbar
        style={{
          padding: "0px",
          overflow: "auto",
          display: "flex",
          justifyContent: "flex-end",
          gap: "20px",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          style={{ backgroundColor: "grey" }}
          size="small"
          onClick={() => {
            if (window.confirm("Are you sure you want to cancel?")) {
              window.history.back();
            }
          }}
        >
          Back
        </Button>
        <Button
          id="department_create_save"
          variant="contained"
          size="small"
          sx={{ m: 0 }}
          onClick={() => {
            if (departmentName) {
              onSubmit();
            } else {
              toast.error("department name compulsory");
            }
          }}
        >
          {id != 0 ? "Update" : "Save"}
        </Button>
      </Toolbar>
    </Box>
  );
}
