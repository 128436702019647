import * as React from "react";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  TextField,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import PreviewIcon from "@mui/icons-material/Preview";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";

export default function UserGroups() {
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [usersGroups, setUsersGroups] = React.useState([]);
  const userType = localStorage.getItem("type");
  const userID = localStorage.getItem("id");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const GroupDelete = (id) => {
    axios
      .delete(BASE_URL + `userGroups/${id}`)
      .then((res) => {
        toast.success(`Group Deleted Successfully`);
        fetchUsersGroupsData();
      })
      .catch((err) => {
        console.log(`Error deleting File: ${err}`);
      });
  };

  const fetchUsersGroupsDataSearch = async () => {
    let whereConditions = "";
    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Group Name": "ug.name",
        "Created By": "u.username",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }

    let userTypeCondition = "";
    if (userType != 1) {
      userTypeCondition = `and ug.createdBy = '${userID}'`;
    }

    let query = {
      query: `SELECT ug.id, ug.name, ug.createdBy AS createdBy, u.username AS createdByUsername FROM userGroups ug LEFT JOIN users u ON ug.createdBy = u.id ${whereConditions} ${userTypeCondition}`,
    };
    try {
      const response = await axios.post(BASE_URL + `dynamic`, query);
      setUsersGroups(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUsersGroupsData = async () => {
    let query;
    if (userType == 1) {
      query = {
        query: `SELECT ug.id, ug.name, u.username AS createdByUsername FROM userGroups ug LEFT JOIN users u ON ug.createdBy = u.id ORDER BY ug.id DESC`,
      };
    } else {
      query = {
        query: `SELECT ug.id, ug.name, u.username AS createdByUsername FROM userGroups ug LEFT JOIN users u ON ug.createdBy = u.id WHERE ug.createdBy = '${userID}' ORDER BY ug.id DESC`,
      };
    }
    try {
      const response = await axios.post(BASE_URL + `dynamic`, query);
      setUsersGroups(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchUsersGroupsData();
  }, []);

  return (
    <Box sx={{ margin: "5px" }}>
      <Toolbar style={{ padding: "10px 5px" }}>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/usergroups/editUsergroups/0"
        >
          <b>create user groups</b>
        </Button>
      </Toolbar>
      <Grid
        style={{ padding: "10px 5px" }}
        container
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item xs={12} sm={3} md={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={searchBy}
            options={[
              { key: "name", label: "Group Name" },
              ...(userType == 1 ? [{ key: "createdBy", label: "Created By" }] : []),
            ]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search By"
                InputLabelProps={{
                  shrink: true, // Ensure label doesn't override input line on smaller screens
                }}
              />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <TextField
            style={{ width: "100%" }}
            id="outlined-basic"
            label={"enter " + searchBy}
            size="small"
            value={searchKey}
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              fetchUsersGroupsDataSearch();
            }}
          >
            <SearchIcon />
          </Button>
        </Grid>
        <Grid item xs={6} sm={1.5} md={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              fetchUsersGroupsData();
              setSearchBy("");
              setSearchKey("");
            }}
          >
            <RestartAltIcon />
          </Button>
        </Grid>
      </Grid>
      <br />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table mt={5} size={"small"} width="100%">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ border: 1 }}>
                  <b>Sr. No</b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Groups Name </b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Created By </b>
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersGroups?.length > 0 ? (
                usersGroups
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, ind) => (
                    <TableRow key={ind}>
                      <TableCell align="center" sx={{ border: 1 }}>
                        {ind + 1}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ cursor: "pointer" }}
                        sx={{ border: 1 }}
                      >
                        {item?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ cursor: "pointer" }}
                        sx={{ border: 1 }}
                      >
                        {item?.createdByUsername}
                      </TableCell>
                      <TableCell align="center" sx={{ border: 1 }}>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                          spacing="2rem"
                        >
                          <Tooltip title="Edit User" placement="top-start">
                            <Button
                              component={Link}
                              to={`/usergroups/editUsergroups/${item.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Delete document"
                            placement="top-start"
                          >
                            <Button
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Do you really want to delete?"
                                  )
                                ) {
                                  GroupDelete(item.id);
                                }
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <b>Data not found</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={usersGroups.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        ></TablePagination>
      </Paper>
    </Box>
  );
}
