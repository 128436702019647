import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  TextField,
  Paper,
  TablePagination,
} from "@mui/material";
import React from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import PreviewIcon from "@mui/icons-material/Preview";
import moment from "moment";

export default function InternalAuditCorrectiveList() {
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const [page, setPage] = React.useState(0);
  const [auditList, setAuditList] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchInternalAuditDataSearch = async () => {
    let whereConditions = "";
    if (searchBy !== "" && searchKey != "") {
      let colName = {
        "Report Number": "reportNumber",
        "NC Number": "NCNumber",
        Department: "department",
        Auditor: "auditor",
        Auditee: "auditee",
      }[searchBy];
      whereConditions = `where ${colName} like '%${searchKey}%'`;
    }
    let query = {
      query: `SELECT * FROM internalAuditCorrectiveActions ${whereConditions} order by date DESC`,
    };
    try {
      const response = await axios.post(BASE_URL + `dynamic`, query);
      setAuditList(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchInternalAuditData = async () => {
    let query = {
      query: `SELECT * FROM internalAuditCorrectiveActions order by date DESC`,
    };
    try {
      const response = await axios.post(BASE_URL + `dynamic`, query);
      setAuditList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const AuditCorrectiveDelete = (id) => {
    axios
      .delete(BASE_URL + `internalAuditCorrectiveActions/${id}`)
      .then((res) => {
        toast.success(`Audit Corrective Deleted Successfully`);
        fetchInternalAuditData();
      })
      .catch((err) => {
        console.log(`Error deleting File: ${err}`);
      });
  };

  React.useEffect(() => {
    fetchInternalAuditData();
  }, []);
  return (
    <Box style={{ padding: "5px 5px" }}>
      <Toolbar style={{ padding: "10px 5px" }}>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/internalaudit/0"
        >
          <b>create internal audit</b>
        </Button>
      </Toolbar>
      <Grid
        style={{ padding: "10px 5px" }}
        container
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid item xs={12} sm={3} md={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={searchBy}
            options={[
              { key: "reportNumber", label: "Report Number" },
              { key: "NCNumber", label: "NC Number" },
              { key: "department", label: "Department" },
              { key: "auditor", label: "Auditor" },
              { key: "auditee", label: "Auditee" },
            ]}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search By"
                InputLabelProps={{
                  shrink: true, // Ensure label doesn't override input line on smaller screens
                }}
              />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <TextField
            style={{ width: "100%" }}
            id="outlined-basic"
            label={"enter " + searchBy}
            size="small"
            value={searchKey}
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={1.5} md={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              fetchInternalAuditDataSearch();
            }}
          >
            <SearchIcon />
          </Button>
        </Grid>
        <Grid item xs={6} sm={1.5} md={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              fetchInternalAuditData();
              setSearchBy("");
              setSearchKey("");
            }}
          >
            <RestartAltIcon />
          </Button>
        </Grid>
      </Grid>
      <br />
      <Paper sx={{ width: "100%", mt: 3, mb: 1 }}>
        <TableContainer>
          <Table mt={5} size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ border: 1 }}>
                  <b>Sr. No</b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Report No </b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>NC No </b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Date </b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Department</b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Auditor</b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Auditee</b>
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                  <b>Action</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {auditList?.length > 0 ? (
                auditList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, ind) => (
                    <TableRow key={ind}>
                      <TableCell align="center" sx={{ border: 1 }}>
                        {ind + 1}
                      </TableCell>
                      <TableCell align="left" sx={{ border: 1 }}>
                        {item.reportNumber}
                      </TableCell>
                      <TableCell align="left" sx={{ border: 1 }}>
                        {item.NCNumber}
                      </TableCell>
                      <TableCell align="left" sx={{ border: 1 }}>
                        {moment(item.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="left" sx={{ border: 1 }}>
                        {item.department}
                      </TableCell>
                      <TableCell align="left" sx={{ border: 1 }}>
                        {item.auditor}
                      </TableCell>
                      <TableCell align="left" sx={{ border: 1 }}>
                        {item.auditee}
                      </TableCell>
                      <TableCell align="center" sx={{ border: 1 }}>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                          spacing="2rem"
                        >
                          <Tooltip title="Edit audit" placement="top-start">
                            <Button
                              component={Link}
                              to={`/internalaudit/${item.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Delete audit" placement="top-start">
                            <Button
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Do you really want to delete?"
                                  )
                                ) {
                                  AuditCorrectiveDelete(item.id);
                                }
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <b>Data not found</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={auditList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        ></TablePagination>
      </Paper>
    </Box>
  );
}
