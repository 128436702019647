import React, { useState } from "react";
import Box from "@mui/material/Box";
import { HashRouter, Routes, Route } from "react-router-dom";

import Navbar from "./../common/navbar";
import FileManager from "../fileManager/FileManager";
import Login from "./../login/Login";

import { ToastContainer } from "react-toastify";

import { drawerWidth } from "./../common/navbar";
import Request from "../requests/request";
import ViewFile from "../fileManager/viewFile";
import UserList from "../userMaster/userList";
import EditUserMaster from "../userMaster/editUserMaster";
import UserGroups from "../userGroups/userGroupList";
import Dashboard from "../dashboard/dashboard";
import UserGroupCreateEdit from "../userGroups/userGroupcreateedit";
import InternalAuditObservation from "../internalAuditObservation/internalAuditObservation";
import InternalAuditCorrective from "../internalAuditCorrective/internalAuditCorrective";
import InternalAuditCorrectiveList from "../internalAuditCorrective/internalAuditCorrectiveList";
import InternalAuditObservationList from "../internalAuditObservation/internalAuditObservationList";
import DepartmentList from "../department/departmentList";
import EditDepartment from "../department/editDepartment";

export default function Main(props) {
  const [title, setTitle] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );

  return (
    <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
      <HashRouter>
        {localStorage.getItem("isLoggedIn") && (
          <Navbar title={title} setIsLoggedIn={setIsLoggedIn} />
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pt: 15, //before is 9
            width: `calc(100% - ${drawerWidth}px)`,
            height: "100%",
          }}
        >
          <Routes>
            {!localStorage.getItem("isLoggedIn") && (
              <Route
                index
                element={
                  <Login
                    setIsLoggedIn={setIsLoggedIn}
                    setLoader={props.setLoader}
                  />
                }
              />
            )}
            {localStorage.getItem("isLoggedIn") && (
              <Route index element={<FileManager />} />
            )}

            <Route path="/filemanager" element={<FileManager />}></Route>
            <Route path="/department" element={<DepartmentList />}></Route>
            <Route
              path="/editdepartment/:id"
              element={<EditDepartment />}
            ></Route>
            <Route path="/dashboard" element={<Dashboard />}></Route>
            <Route path="/request" element={<Request />}></Route>
            <Route path="/viewfiles/:id/:type?" element={<ViewFile />} />
            <Route path="/users" element={<UserList />}></Route>
            <Route
              path="/internalaudit/:id"
              element={<InternalAuditCorrective />}
            ></Route>
            <Route
              path="/internal_Audit_List"
              element={<InternalAuditCorrectiveList />}
            ></Route>
            <Route
              path="/internal_Observation/:id"
              element={<InternalAuditObservation />}
            ></Route>
            <Route
              path="/internal_Observation_List"
              element={<InternalAuditObservationList />}
            ></Route>
            <Route path="/users/addUser" element={<EditUserMaster />}></Route>
            <Route
              path="/users/editUser/:id"
              element={<EditUserMaster />}
            ></Route>
            <Route path="/usergroups" element={<UserGroups />}></Route>
            <Route
              path="/usergroups/editUsergroups/:id"
              element={<UserGroupCreateEdit />}
            ></Route>
          </Routes>
          <br/><br/>
        </Box>
      </HashRouter>
      <ToastContainer />
    </Box>
  );
}
