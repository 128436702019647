import {
  Box,
  Button,
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../../global";
import axios from "axios";

export default function DepartmentList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [dept, setDept] = React.useState([]);

  const departmentDelete = (id) => {
    axios
      .delete(BASE_URL + `departments/${id}`)
      .then((res) => {
        toast.success(`Departments Deleted Successfully`);
        fetchDeptData();
      })
      .catch((err) => {
        console.log(`Error deleting File: ${err}`);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchDeptData = async () => {
    try {
      const response = await axios.get(BASE_URL + `departments`);
      setDept(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchDeptData();
  }, []);

  return (
    <Box sx={{ m: 2 }}>
      <Toolbar style={{ padding: "10px 5px" }}>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/editdepartment/0"
        >
          <b>create department</b>
        </Button>
      </Toolbar>
      <br />

      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table mt={5} size={"small"} width="100%">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ border: 1 }}>
                  <b>Sr. No</b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Department Name </b>
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dept?.length > 0 ? (
                dept
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, ind) => (
                    <TableRow key={ind}>
                      <TableCell align="center" sx={{ border: 1 }}>
                        {ind + 1}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ cursor: "pointer" }}
                        sx={{ border: 1 }}
                      >
                        {item?.name}
                      </TableCell>
                      <TableCell align="center" sx={{ border: 1 }}>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                          spacing="2rem"
                        >
                          <Tooltip title="Edit User" placement="top-start">
                            <Button
                              component={Link}
                              to={`/editdepartment/${item.id}`}
                            >
                              <PreviewIcon />
                            </Button>
                          </Tooltip>
                          <Tooltip
                            title="Delete document"
                            placement="top-start"
                          >
                            <Button
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Do you really want to delete?"
                                  )
                                ) {
                                  departmentDelete(item.id);
                                }
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <b>Data not found</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={dept.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        ></TablePagination>
      </Paper>
    </Box>
  );
}
