import { Button, Grid, Toolbar, Typography, TextField } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { BASE_URL } from "../../global";
import axios from "axios";

export default function UserGroupCreateEdit() {
  const [groupNames, setGroupNames] = React.useState("");
  const params = useParams();
  const userID = localStorage.getItem("id");
  const refresh = () => {
    window.location.reload(false);
  };
  const onSubmit = () => {
    const jsonData = {
      name: groupNames,
      ...(params.id == 0 && { createdBy: userID }),
    };
  
    const request = params.id != 0
      ? axios.patch(`${BASE_URL}userGroups/${params.id}`, jsonData)
      : axios.post(`${BASE_URL}userGroups`, jsonData);
  
    request
      .then((res) => {
        const message = params.id !== 0 ? "Group Updated!" : "New Group Added!";
        toast(message);
        setTimeout(refresh, 500);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  

  const getGroupName = () => {
    axios
      .get(BASE_URL + `userGroups/${params.id}`)
      .then((res) => {
        setGroupNames(res.data[0].name);
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };
  React.useEffect(() => {
    if (params.id != 0) {
      getGroupName();
    }
  }, [params.id]);

  return (
    <div style={{ margin: "10px" }}>
      <Typography variant="h6" component="h6" style={{ float: "left" }}>
        {params.id != 0 ? "Update Group" : "Create New Group"}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="outlined-basic"
            label="Group name *"
            size="small"
            fullWidth
            value={groupNames || ""}
            variant="outlined"
            onChange={(e) => setGroupNames(e.target.value)}
          />
        </Grid>
      </Grid>
      <Toolbar style={{ padding: "0px" }} sx={{ mt: 5 }}>
        <Button
          style={{ backgroundColor: "grey" }}
          variant="contained"
          size="small"
          onClick={() => {
            window.history.back();
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{ ml: 2 }}
          onClick={() => {
            if (groupNames) {
              onSubmit();
            } else {
              toast.error("Group name compulsory");
            }
          }}
        >
          {params.id != 0 ? "Update" : "Save"}
        </Button>
      </Toolbar>
    </div>
  );
}
