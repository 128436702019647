import * as React from "react";
import {
  fetchUsers,
  fetchchangeRequestmodules,
} from "../fileManager/fetchHandler";
import moment from "moment";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid cadetblue",
  padding: "20px",
  textAlign: "center",
  borderRadius: "5px",
};

const Request = () => {
  const navigate = useNavigate();
  const [searchBy, setSearchBy] = React.useState(null);
  const [requestData, setrequestData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [users, setUsers] = React.useState([]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [rejectReason, setRejectReason] = React.useState("");
  const [rejectid, setRejectId] = React.useState(0);
  const handleClose = () => {
    setModalOpen(false);
  };

  let userID = localStorage.getItem("id") || "";

  const fetchRequestData = async () => {
    try {
      const changeRequestData = await fetchchangeRequestmodules();
      setrequestData(changeRequestData);
      const userData = await fetchUsers();
      setUsers(userData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const ApproveStatus = async (id) => {
    try {
      await axios.patch(BASE_URL + `changeRequest/${id}`, {
        approvalStatus: 2,
        approvedBy: userID,
        approvedDate:moment(new Date()).format('YYYY-MM-DD'),
      });
      toast.success(`File Approved Successfully`);
      fetchRequestData();

      const filterfilePostdata = requestData.find((el) => el?.id === id);

      if (filterfilePostdata) {
        const payload = {
          parentFolderId: filterfilePostdata.parentFolderId,
          name: filterfilePostdata.fileName,
          filePath: filterfilePostdata.filePath,
          createdBy: filterfilePostdata.createdBy,
          sequenceId: filterfilePostdata.sequenceId,
        };

        const filesRes = await axios.post(BASE_URL + `files`, payload);

        await axios.patch(BASE_URL + `changeRequest/${id}`, {
          fileId: filesRes.data.insertId,
        });
      }
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  };

  const RejectStatus = async (id) => {
    try {
      await axios.patch(BASE_URL + `changeRequest/${id}`, {
        approvalStatus: 3,
        reason:rejectReason
      });
      toast.success(`File Rejected Successfully`);
      fetchRequestData();

      const filterfilePostdata = requestData.find((el) => el?.id === id);

      if (filterfilePostdata.fileId) {
        await axios.delete(BASE_URL + `files/${filterfilePostdata.fileId}`);
        console.log(`File deleted successfully`);
      }
      setModalOpen(false);
    } catch (err) {
      console.log(`Error Reject File: ${err}`);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fileDelete = (id) => {
    axios
      .delete(BASE_URL + `changeRequest/${id}`)
      .then((res) => {
        toast.success(`File Deleted Successfully`);
        fetchRequestData();
      })
      .catch((err) => {
        console.log(`Error deleting File: ${err}`);
      });
  };
  React.useEffect(() => {
    fetchRequestData();
  }, []);

  const filterChangeRequestStatus = async (selectedValue) => {
    setSearchBy(selectedValue);
    try {
      let response;
      if (selectedValue == null) {
        response = await axios.post(BASE_URL + "dynamic", {
          query: "SELECT * FROM changeRequest ORDER BY id DESC",
        });
      } else {
        response = await axios.post(BASE_URL + "dynamic", {
          query: `SELECT * FROM changeRequest WHERE approvalStatus = '${selectedValue}' ORDER BY id DESC`,
        });
      }
      setrequestData(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  

  const ShowRejectModal = () => {
    console.log({rejectid})
    return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        size="md"
      >
        <Box sx={modalStyle}>
          <TextField
            id="outlined-basic"
            label="Reason For Rejection"
            value={rejectReason}
            multiline
            maxRows={5}
            rows={5}
            size="small"
            fullWidth
            variant="outlined"
            style={{ marginBottom: "5px" }}
            onChange={(e) => setRejectReason(e.target.value)}
          />
          <Tooltip title="Send Rejection Reason" placement="top-start">
            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ m: 2 }}
              onClick={() => {
                RejectStatus(rejectid);
              }}
              disabled={rejectReason === ""}
            >
              Reject
            </Button>
          </Tooltip>
        </Box>
      </Modal>
    );
  };

  return (
    <Box style={{ padding: "5px 5px" }}>
      {ShowRejectModal()}
      <div
        className="mb-7 pb-5"
        style={{
          display: "flex",
          float: "left",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <FormControl style={{ width: "180px" }}>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={searchBy}
            label="Status"
            size="small"
            onChange={(event) => filterChangeRequestStatus(event.target.value)}
          >
            <MenuItem value={null}>Reset</MenuItem>
            <MenuItem value={1}>Pending</MenuItem>
            <MenuItem value={2}>Approved</MenuItem>
            <MenuItem value={3}>Rejected</MenuItem>
          </Select>
        </FormControl>
      </div>
      <br />
      <Paper sx={{ width: "100%", mt: 3, mb: 1 }}>
        <TableContainer>
          <Table mt={5} size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ border: 1 }}>
                  <b>Sr. No</b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Document Name </b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Created By</b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Uploaded Date</b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Approved Date</b>
                </TableCell>
                <TableCell align="left" sx={{ border: 1 }}>
                  <b>Approval Status</b>
                </TableCell>
                <TableCell align="center" sx={{ border: 1 }}>
                  <b>Action</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {requestData?.length > 0 ? (
                requestData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, ind) => (
                    <TableRow key={ind}>
                      <TableCell align="center" sx={{ border: 1 }}>
                        {ind + 1}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(`/viewfiles/${item.id}`)}
                        sx={{ border: 1 }}
                      >
                        {item?.fileName}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ textTransform: "capitalize" }}
                        sx={{ border: 1 }}
                      >
                        {item.createdBy
                          ? users.find((user) => user.id === item.createdBy)
                              ?.userName
                          : item.createdBy}
                      </TableCell>
                      <TableCell align="left" sx={{ border: 1 }}>
                        {moment(item?.uploadedDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell align="left" sx={{ border: 1 }}>
                        {moment(item?.approvedDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell align="left" sx={{ border: 1 }}>
                        {item?.approvalStatus === 1 ? (
                          <h6 style={{ color: "orange" }}>Pending</h6>
                        ) : item?.approvalStatus === 2 ? (
                          <h6 style={{ color: "green" }}>Approved</h6>
                        ) : item?.approvalStatus === 3 ? (
                          <h6 style={{ color: "red" }}>Rejected</h6>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell align="center" sx={{ border: 1 }}>
                        <ButtonGroup
                          size="small"
                          aria-label="small button group"
                          spacing="2rem"
                        >
                          <Tooltip
                            title="Approve document"
                            placement="top-start"
                          >
                            <span>
                              <Button
                                disabled={item.approvalStatus == 2}
                                color="success"
                                onClick={() => ApproveStatus(item.id)}
                              >
                                Approve
                              </Button>
                            </span>
                          </Tooltip>
                          <Tooltip
                            title="Reject document"
                            placement="top-start"
                          >
                            <span>
                              <Button
                                disabled={item.approvalStatus == 2}
                                color="error"
                                onClick={() => {
                                  setModalOpen(true);
                                  setRejectId(item.id);
                                  setRejectReason(item.reason);
                                }}
                              >
                                Reject
                              </Button>
                            </span>
                          </Tooltip>
                          <Tooltip
                            title="Delete document"
                            placement="top-start"
                          >
                            <span>
                              <Button
                                onClick={(e) => {
                                  if (
                                    window.confirm(
                                      "Do you really want to delete?"
                                    )
                                  ) {
                                    fileDelete(item.id);
                                  }
                                }}
                              >
                                <DeleteIcon />
                              </Button>
                            </span>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <b>Data not found</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={requestData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        ></TablePagination>
      </Paper>
    </Box>
  );
};

export default Request;
