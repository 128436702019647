import * as React from "react";
import {
  Backdrop,
  Breadcrumbs,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Modal,
  TextField,
  Tooltip,
  Autocomplete,
  Toolbar,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import FolderIcon from "@mui/icons-material/Folder";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HomeIcon from "@mui/icons-material/Home";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import axios from "axios";
import { BASE_URL } from "../../global";
import { toast } from "react-toastify";
import moment from "moment";
import {
  fetchDerivedPermissions,
  fetchFiles,
  fetchFilesPermission,
  fetchFolder,
  fetchFoldersPermission,
  fetchNestedFolder,
  fetchPermissions,
  fetchUserGroups,
  fetchUsers,
  fetchchangeRequest,
  handleFileUpload,
  searchTables,
} from "./fetchHandler";
import ViewFile from "./viewFile";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function FileManager() {
  const navigate = useNavigate();
  let userID = localStorage.getItem("id") || "";
  let userType = localStorage.getItem("type") || "";
  let userActions = localStorage
    .getItem("actions")
    ?.split(",")
    ?.map((e) => e)
    ?.map((e) => Number(e));
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [folderName, setFolderName] = React.useState("");
  const [fileNameSearch, setfileNameSearch] = React.useState("");
  const [parentFolderIds, setparentFolderIds] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [editingId, setEditingId] = React.useState(null);
  const [editingType, setEditingType] = React.useState(null);
  const [editedName, setEditedName] = React.useState("");
  const [breadcrumbs, setBreadcrumbs] = React.useState([]);
  const [userlist, setUserlist] = React.useState([]);
  const [selectuserlist, setSelectUserlist] = React.useState(null);
  const [selectuserid, setSelectUserid] = React.useState([]);
  const [PermssionModalOpen, setOpenPermssionModal] = React.useState(false);
  const [isInputVisible1, setIsInputVisible1] = React.useState(false);
  const [isInputVisible2, setIsInputVisible2] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const [searchKey1, setSearchKey1] = React.useState("");
  const [searchKey2, setSearchKey2] = React.useState("");
  const [Permissions, setPermissions] = React.useState([]);
  const [PermissionsDerived, setPermissionsDerived] = React.useState([]);
  const breadcrumbIds = breadcrumbs
    .map((breadcrumb) => breadcrumb.id)
    .join(",");

  const handleOpenPermssionModal = () => setOpenPermssionModal(true);
  const handleClosePermssionModal = () => {
    setOpenPermssionModal(false);
    setSelectUserlist(null);
    setSearchKey1("");
    setSearchKey2("");
    setIsInputVisible1(false);
    setIsInputVisible2(false);
    // setPermissionsDerived([]);
    // setparentFolderIds(0);
  };
  const fetchData = async () => {
    try {
      const folderData = await fetchFolder(0);
      const filesData = await fetchFiles(0);
      const filesPermissionData = await fetchFilesPermission();
      const foldersPermissionData = await fetchFoldersPermission();
      const changeRequestData = await fetchchangeRequest(0);
      setRows([
        ...filesData,
        ...folderData,
        ...changeRequestData,
        ...filesPermissionData,
        ...foldersPermissionData,
      ]);
      const fetchUserGroupsData = await fetchUserGroups();
      setUserlist([...fetchUserGroupsData]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFileSearch = async () => {
    const searchData = await searchTables(fileNameSearch);
    setRows([...searchData]);
    console.log(searchData);
  };

  const fetchPermissionsData = async () => {
    try {
      const permissionsData = await fetchPermissions(searchKey1);
      setPermissions(permissionsData);
    } catch (error) {
      console.error("Error fetching permissions data:", error);
    }
  };
  const fetchDerivedPermissionsData = async () => {
    try {
      const fetchDerivedPermissionsData = await fetchDerivedPermissions(
        breadcrumbIds,
        searchKey2
      );
      setPermissionsDerived(fetchDerivedPermissionsData);
    } catch (error) {
      console.error("Error fetching permissions data:", error);
    }
  };

  const filterUsers = async () => {
    try {
      const userData = await fetchUsers();
      setUsers(userData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleEditClick = (id, name, type) => {
    setEditingId(id);
    setEditedName(name);
    setEditingType(type);
  };

  const FolderFileRename = (id, type) => {
    let payload = {};
    let itemType = "";
    switch (type) {
      case "folders":
        itemType = "Folder";
        payload.name = editedName;
        break;
      case "files":
        itemType = "files";
        payload.name = editedName;
        break;
      case "changeRequest":
        itemType = "File";
        payload.fileName = editedName;
        break;
    }
    axios
    .get(BASE_URL + `${type}?_where=(name,eq,${editedName})~and(parentFolderId,eq,${parentFolderIds || 0})`)
    .then((res) => {
      if (res.data.length > 0) {
        toast.error(`${itemType} already exist!`);
      }
      else{
        axios
          .patch(BASE_URL + `${type}/${id}`, payload)
          .then((res) => {
            setEditingId(null);
            toast.success(`${itemType} rename successfully`);
            handleFolderNested({ id: parentFolderIds });
          })
          .catch((error) => {
            console.log(error);
          });
      }})
      .catch((error) => {
        console.log(error);
      });
  };

  const FolderFileRenameCancel = () => {
    setEditingId(null);
    setEditedName("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  function findMaxSequenceId(rows) {
    if (rows.length === 0) {
      return 0; // Return 0 if rows is empty
    }
    let maxSequenceId = -Infinity;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].sequenceId > maxSequenceId) {
        maxSequenceId = rows[i].sequenceId;
      }
    }
    return maxSequenceId;
  }
  const handleCreateFolder = () => {
    if (!folderName.trim()) {
      toast.error("Enter Folder Name");
      return;
    }
    if (userType != 1 && parentFolderIds == 0) {
      toast.error("You can't create folders within parent folders.");
      return;
    }
    let url = BASE_URL;
    axios
    .get(BASE_URL + `folders?_where=(name,eq,${folderName})~and(parentFolderId,eq,${parentFolderIds || 0})`)
    .then((res) => {
      if (res.data.length > 0) {
        toast.error("Folder already exists");
      }
      else{
        let payload = {
          name: folderName,
          parentFolderId: parentFolderIds || 0,
          createdBy: userID,
          sequenceId: findMaxSequenceId(rows) + 1,
        };
        axios
          .post(url + "folders", payload)
          .then((res) => {
            toast.success("Folder Created Successfully");
            setFolderName("");
            handleFolderNested({ id: parentFolderIds });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const handleFolderDelete = (type, id) => {
    let itemType = "";
    switch (type) {
      case "folders":
        itemType = "Folder";
        break;
      case "files":
        itemType = "File";
        break;
      case "changeRequest":
        itemType = "File";
        break;
    }

    axios
      .delete(BASE_URL + `${type}/${id}`)
      .then(async () => {
        toast.success(`${itemType} Deleted Successfully`);
        if (type == "folders") {
          const nestedFolderQuery = {
            query: `DELETE FROM folders WHERE parentFolderId = ${id}`,
          };
          const nestedFilesQuery = {
            query: `DELETE FROM files WHERE parentFolderId = ${id}`,
          };
          const nestedChnageRequestQuery = {
            query: `DELETE FROM changeRequest WHERE parentFolderId = ${id}`,
          };
          await axios
            .post(BASE_URL + `dynamic`, nestedFolderQuery)
            .then((res) => console.log(res));
          await axios
            .post(BASE_URL + `dynamic`, nestedFilesQuery)
            .then((res) => console.log(res));
          await axios
            .post(BASE_URL + `dynamic`, nestedChnageRequestQuery)
            .then((res) => console.log(res));
        }
      })
      .then(() => {
        console.log(
          `Deleted related ${itemType} for parent folder with ID ${id}`
        );
        handleFolderNested({ id: parentFolderIds });
      })
      .catch((err) => {
        console.log(`Error deleting ${itemType}: ${err}`);
      });
  };

  const handleFolderNested = async (row) => {
    setparentFolderIds(row.id);

    const folderIndex = breadcrumbs.findIndex((folder) => folder.id === row.id);
    let breadcrumbIdsMap;
    if (folderIndex === -1) {
      breadcrumbIdsMap = [...breadcrumbs, { id: row.id, name: row.name }];
      setBreadcrumbs([...breadcrumbs, { id: row.id, name: row.name }]);
    } else {
      breadcrumbIdsMap = breadcrumbs.slice(0, folderIndex + 1);
      setBreadcrumbs(breadcrumbs.slice(0, folderIndex + 1));
    }

    const folderData = await fetchNestedFolder(row.id);
    const filesData = await fetchFiles(row.id);
    const changeRequestData = await fetchchangeRequest(row.id);
    setRows([...filesData, ...folderData, ...changeRequestData]);

    let breadcrumbIds = breadcrumbIdsMap
      .map((breadcrumb) => breadcrumb.id)
      .join(",");

    const fetchDerivedPermissionsData = await fetchDerivedPermissions(
      breadcrumbIds,
      searchKey2
    );
    setPermissionsDerived(fetchDerivedPermissionsData);
  };

  const handleBreadcrumbsHome = async () => {
    setparentFolderIds(0);
    setBreadcrumbs([]);
    fetchData();
  };

  const handleAuthAccess = () => {
    if (!selectuserlist) {
      toast.error("Please select a user or group");
      return;
    }

    let payload = {
      documentType: selectuserid[1] === "folders" ? 1 : 2,
      documentid: selectuserid[0],
      userType: selectuserlist?.users === "userGroups" ? 2 : 1,
      userId: selectuserlist?.users_id || "",
    };
    axios
      .get(BASE_URL + "permissions")
      .then((response) => {
        const existingPermission = response.data.find((permission) => {
          return (
            permission.documentType === payload.documentType &&
            permission.documentId === payload.documentid &&
            permission.userType === payload.userType &&
            permission.userId === payload.userId
          );
        });

        if (existingPermission) {
          toast.info(
            "File permission already exists for the selected user or group"
          );
        } else {
          axios
            .post(BASE_URL + "permissions", payload)
            .then((res) => {
              toast.success("File permission added successfully");
              // handleFolderNested({ id: parentFolderIds });
              fetchPermissionsData();
              fetchDerivedPermissionsData();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const permisssionDelete = (id) => {
    axios
      .delete(BASE_URL + `permissions/${id}`)
      .then((res) => {
        toast.success(`User permissions deleted Successfully`);
        // handleFolderNested({ id: parentFolderIds });
        fetchPermissionsData();
        fetchDerivedPermissionsData();
      })
      .catch((err) => {
        console.log(`Error deleting permissions: ${err}`);
      });
  };

  function AuthModal() {
    return (
      <div>
        <Modal
          open={PermssionModalOpen}
          onClose={handleClosePermssionModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "calc(100% - 30%)",
              backgroundColor: "white",
              // border: "2px solid #000",
              boxShadow: 24,
              padding: "10px",
            }}
          >
            <div
              style={{
                width: "95%",
                margin: "5px auto",
                border: "2px solid black;",
                padding: "5px",
                fontSize: "13px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <h4 style={{ textAlign: "left" }}>Permissions</h4>
                <Button
                  variant="contained"
                  size="small"
                  color="error"
                  // style={{ width: "100%",height:"35px" }}
                  onClick={handleClosePermssionModal}
                >
                  X
                </Button>
              </div>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={12} sm={10} md={10}>
                  <Autocomplete
                    size="small"
                    id="combo-box-demo"
                    options={userlist}
                    value={selectuserlist}
                    getOptionLabel={(option) =>
                      option.users === "userGroups"
                        ? `${option.userName} (Group)`
                        : option.userName || ""
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="User / GroupName List *" />
                    )}
                    onChange={(e, val) => {
                      setSelectUserlist(val);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2} textAlign={"left"}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{ width: "100%", height: "37px" }}
                    onClick={handleAuthAccess}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              <br />
              <div style={{ height: "200px", overflowX: "auto" }}>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    border: "1px solid #ddd",
                  }}
                >
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      background: "#fff",
                    }}
                  >
                    <tr
                      style={{
                        fontSize: "11px",
                        borderBottom: "1px solid #ddd",
                      }}
                    >
                      <th
                        style={{
                          borderRight: "1px solid #ddd",
                          padding: "8px",
                          width: "10%",
                        }}
                      >
                        User / Group
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid #ddd",
                          padding: "8px",
                          width: "80%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <span>Added User / GroupName List</span>
                          {isInputVisible1 ? (
                            <CancelIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsInputVisible1(false);
                                setSearchKey1("");
                              }}
                            />
                          ) : (
                            <FilterAltIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => setIsInputVisible1(true)}
                            />
                          )}
                          {isInputVisible1 && (
                            <input
                              type="text"
                              placeholder="Search User..."
                              style={{
                                padding: "3px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                fontSize: "16px",
                                width: "200px",
                                outline: "none",
                                boxShadow: "0 0 5px rgba(0, 123, 255, 0.5)",
                              }}
                              value={searchKey1}
                              onChange={(e) => setSearchKey1(e.target.value)}
                            />
                          )}
                        </div>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid #ddd",
                          padding: "8px",
                          width: "10%",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Permissions?.filter(
                      (item) =>
                        item.documentId == selectuserid[0] &&
                        item.documentType ==
                          (selectuserid[1] == "folders" ? 1 : 2)
                    )?.map((item) => (
                      <tr key={item.id}>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {item.userType == 1 ? (
                            <PersonIcon />
                          ) : (
                            <PeopleAltIcon />
                          )}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {item?.userGroupName}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                          }}
                        >
                          <Tooltip
                            title="Delete document"
                            placement="top-start"
                          >
                            <Button
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Really want to delete User / GroupName ?"
                                  )
                                ) {
                                  permisssionDelete(item.id);
                                }
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <h6
                style={{
                  textAlign: "center",
                  textTransform: "capitalize",
                  margin: "10px 0px",
                }}
              >
                Derived user / group name list
              </h6>
              <div style={{ height: "200px", overflowX: "auto" }}>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    border: "1px solid #ddd",
                  }}
                >
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      background: "#fff",
                    }}
                  >
                    <tr
                      style={{
                        fontSize: "11px",
                        borderBottom: "1px solid #ddd",
                        position: "sticky",
                      }}
                    >
                      <th
                        style={{
                          borderRight: "1px solid #ddd",
                          padding: "8px",
                          width: "10%",
                        }}
                      >
                        User / Group
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid #ddd",
                          padding: "8px",
                          width: "80%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <span>Added User / GroupName List</span>
                          {isInputVisible2 ? (
                            <CancelIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsInputVisible2(false);
                                setSearchKey2("");
                              }}
                            />
                          ) : (
                            <FilterAltIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => setIsInputVisible2(true)}
                            />
                          )}
                          {isInputVisible2 && (
                            <input
                              type="text"
                              placeholder="Search User..."
                              style={{
                                padding: "3px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                fontSize: "16px",
                                width: "200px",
                                outline: "none",
                                boxShadow: "0 0 5px rgba(0, 123, 255, 0.5)",
                              }}
                              value={searchKey2}
                              onChange={(e) => setSearchKey2(e.target.value)}
                            />
                          )}
                        </div>
                      </th>
                      {/* <th
                        style={{
                          borderRight: "1px solid #ddd",
                          padding: "8px",
                          width: "10%",
                        }}
                      >
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {PermissionsDerived?.map((item) => (
                      <tr key={item.id}>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {item.userType == 1 ? (
                            <PersonIcon />
                          ) : (
                            <PeopleAltIcon />
                          )}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                          }}
                        >
                          {item?.userGroupName}
                        </td>
                        {/* <td
                          style={{
                            border: "1px solid #ddd",
                          }}
                        >
                          <Tooltip
                            title="Delete document"
                            placement="top-start"
                          >
                            <Button
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Really want to delete User / GroupName ?"
                                  )
                                ) {
                                  permisssionDelete(item.id);
                                }
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }

  const LoadingModal = () => {
    return (
      <Modal
        open={loading}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          style={{
            width: "100%",
            height: "100vh",
            bgcolor: "background.paper",
            border: "2px solid cadetblue",
            padding: "30px",
            textAlign: "center",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </Modal>
    );
  };

  const handleMoveFolder = async (row, moveDown) => {
    let { id, parentFolderId, sequenceId } = row;

    try {
      const items = rows?.filter(
        (item) => item.parentFolderId === parentFolderId
      );

      items.sort((a, b) => a.sequenceId - b.sequenceId);

      const currentIndex = items.findIndex((item) => item.id === id);

      // Determine the target index based on moveDown flag
      const targetIndex = moveDown ? currentIndex + 1 : currentIndex - 1;

      if (targetIndex >= 0 && targetIndex < items.length) {
        const targetItem = items[targetIndex];

        // Swap the sequenceIds
        const tempSequenceId = sequenceId;
        row.sequenceId = targetItem.sequenceId;
        targetItem.sequenceId = tempSequenceId;

        await Promise.all([
          axios.patch(BASE_URL + `/${row.type}/${id}`, {
            sequenceId: row.sequenceId,
          }),
          axios.patch(BASE_URL + `/${targetItem.type}/${targetItem.id}`, {
            sequenceId: targetItem.sequenceId,
          }),
        ]);

        handleFolderNested({ id: parentFolderIds });
      } else {
        console.log("Cannot move item. Invalid position.");
      }
    } catch (error) {
      console.error("Error moving item:", error);
    }
  };

  React.useEffect(async () => {
    fetchPermissionsData();
  }, [searchKey1]);

  React.useEffect(async () => {
    fetchDerivedPermissionsData();
  }, [searchKey2]);

  React.useEffect(() => {
    fetchData();
    filterUsers();
  }, []);


  const hasActions =
    userActions?.includes(1) ||
    userActions?.includes(2) ||
    userActions?.includes(3) ||
    userActions?.includes(4) ||
    userActions?.includes(5) ||
    userType == "1";

  return (
    <div style={{ margin: "5px" }}>
      {!selectedFile &&
      <>
      <div style={{ width: "100%", padding: "0px 10px" }}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1.5rem" }}>
            {userType == "1" || userActions?.includes(1) ? (
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 400,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="New Folder Name"
                inputProps={{ "aria-label": "New Folder Name" }}
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: "10px" }}
                aria-label="directions"
                onClick={handleCreateFolder}
              >
                <CreateNewFolderIcon />
              </IconButton>
            </Paper>
            ) : null}
            {userType == "1" || userActions?.includes(2) ? (
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{ m: "4px" }}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  onChange={(e) =>
                    handleFileUpload(
                      e,
                      parentFolderIds,
                      setLoading,
                      handleFolderNested,
                      userID,
                      findMaxSequenceId(rows) + 1
                    )
                  }
                />
              </Button>
            </Paper>
        ) : null}
          </div>
      </div>

      <Box sx={{ margin: "5px" }}>
        <Paper sx={{ padding: "10px 10px", margin: "15px 0px", width: "100%" }}>
          <div>
            <Breadcrumbs>
              <Link
                color="inherit"
                underline="hover"
                style={{ textTransform: "capitalize", textDecoration: "none" }}
                onClick={handleBreadcrumbsHome}
              >
                <HomeIcon style={{ marginBottom: "4px" }} />
              </Link>
              {breadcrumbs.map((folder, index) => (
                <Link
                  key={index}
                  underline="hover"
                  color="inherit"
                  style={{
                    textTransform: "capitalize",
                    textDecoration: "none",
                  }}
                  onClick={() => handleFolderNested(folder)}
                >
                  {folder.name}
                </Link>
              ))}
            </Breadcrumbs>
          </div>
        </Paper>
      </Box>
      <Box sx={{ margin: "5px" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "1.5rem",
              justifyContent: "flex-end",
            }}
          >
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: 300,
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Files...."
                inputProps={{ "aria-label": "Search Files...." }}
                value={fileNameSearch}
                onChange={(e) => setfileNameSearch(e.target.value)}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                variant="standard"
                sx={{ p: "10px" }}
                aria-label="directions"
                onClick={handleFileSearch}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Box>
          <br />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <TableHead>
                <TableRow>
                  {userType === "1" ? (
                    <TableCell
                      component="th"
                      scope="row"
                      padding="none"
                      align="left"
                      sx={{ border: 1 }}
                    ></TableCell>
                  ) : null}
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    align="center"
                    sx={{
                      border: 1,
                      padding: "10px 15px",
                    }}
                  >
                    <b>File/Folder Name</b>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    align="center"
                    sx={{
                      border: 1,
                      padding: "10px 15px",
                    }}
                  >
                    <b>Uploaded By</b>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    align="center"
                    sx={{
                      border: 1,
                      padding: "10px 15px",
                    }}
                  >
                    <b>Last Modified</b>
                  </TableCell>
                  {hasActions && (
                    <TableCell
                      component="th"
                      scope="row"
                      padding="none"
                      align="center"
                      sx={{
                        border: 1,
                        padding: "10px 15px",
                      }}
                    >
                      <b>Actions</b>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.sort((a, b) => a.sequenceId - b.sequenceId)
                  ?.map((row, index) => {
                    const isEditing =
                      editingId === row.id && editingType == row.type;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: "pointer" }}
                      >
                        {userType === "1" ? (
                          <TableCell
                            padding="checkbox"
                            sx={{ border: 1 }}
                            style={{ width: "0px", padding: "0px" }}
                          >
                            <>
                              {index !== 0 && (
                                <Button size="small" sx={{ height: "1.4rem" }}>
                                  <ArrowDropUpIcon
                                    sx={{ fontSize: "3rem" }}
                                    onClick={() => handleMoveFolder(row, false)}
                                  />
                                </Button>
                              )}
                              {index !== rows.length - 1 && (
                                <Button size="small" sx={{ height: "1.4rem" }}>
                                  <ArrowDropDownIcon
                                    sx={{ fontSize: "3rem" }}
                                    onClick={() => handleMoveFolder(row, true)}
                                  />
                                </Button>
                              )}
                            </>
                          </TableCell>
                        ) : null}
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          align="left"
                          sx={{ border: 1, px: 1 }}
                        >
                          {isEditing ? (
                            <TextField
                              variant="standard"
                              value={editedName}
                              onChange={(e) => setEditedName(e.target.value)}
                            />
                          ) : (
                            <div
                              onClick={() => {
                                if (row.type === "files") {
                                  sessionStorage.setItem(
                                    "parentFolderIds",
                                    JSON.stringify(parentFolderIds)
                                  );
                                }

                                if (row.type === "folders") {
                                  handleFolderNested(row);
                                } else {
                                  setSelectedFile(row);
                                  // navigate(`/viewfiles/${row.id}/${row.type}`);
                                }
                              }}
                            >
                              {row.type == "folders" ? (
                                <FolderIcon style={{ color: "#FFBB72" }} />
                              ) : (
                                <Tooltip
                                  sx={{ fontSize: "2rem" }}
                                  arrow
                                  placement="right"
                                  title={
                                    row?.approvalStatus === 1
                                      ? "Pending"
                                      : row?.approvalStatus === 2
                                      ? "Approved"
                                      : row?.approvalStatus === 3
                                      ? `Rejected Reason: ${row?.reason}`
                                      : ""
                                  }
                                >
                                  <InsertDriveFileIcon
                                    sx={{
                                      color:
                                        row?.approvalStatus === 1
                                          ? "#FFD966"
                                          : row?.approvalStatus === 3
                                          ? "#FF6969"
                                          : "#AEAEAE",
                                      "&:hover": {
                                        color:
                                          row?.approvalStatus === 2
                                            ? "#A8DF8E"
                                            : "",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {row.name || row.fileName}
                            </div>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ textTransform: "capitalize" }}
                          sx={{ border: 1 }}
                        >
                          {row.createdBy
                            ? users.find((user) => user.id === row.createdBy)
                                ?.userName
                            : row.createdBy}
                        </TableCell>
                        <TableCell align="left" sx={{ border: 1 }}>
                          {moment(row.lastModified).format("YYYY-MM-DD")}
                        </TableCell>
                        {hasActions && (
                          <TableCell align="left" sx={{ border: 1 }}>
                            <ButtonGroup
                              size="small"
                              aria-label="small button group"
                            >
                              {isEditing ? (
                                <>
                                  <Button
                                    color="success"
                                    size="small"
                                    onClick={() =>
                                      FolderFileRename(row.id, row.type)
                                    }
                                  >
                                    Save
                                  </Button>
                                  &nbsp;
                                  <Button
                                    color="error"
                                    size="small"
                                    onClick={() => FolderFileRenameCancel()}
                                  >
                                    Cancel
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Tooltip
                                    title="Edit document"
                                    placement="top-start"
                                  >
                                    {userType == "1" ||
                                    userActions?.includes(3) ? (
                                      <Button
                                        onClick={() =>
                                          handleEditClick(
                                            row.id,
                                            row.name || row.fileName,
                                            row.type
                                          )
                                        }
                                      >
                                        <EditIcon />
                                      </Button>
                                    ) : null}
                                  </Tooltip>
                                  <Tooltip
                                    title="Delete document"
                                    placement="top-start"
                                  >
                                    {userType == "1" ||
                                    userActions?.includes(4) ? (
                                      <Button
                                        onClick={(e) => {
                                          const itemType =
                                            row.type === "changeRequest"
                                              ? "files"
                                              : row.type;
                                          const confirmed = window.confirm(
                                            `Do you really want to delete ${itemType}?`
                                          );
                                          if (confirmed) {
                                            handleFolderDelete(
                                              row.type,
                                              row.id
                                            );
                                          }
                                        }}
                                      >
                                        <DeleteIcon />
                                      </Button>
                                    ) : null}
                                  </Tooltip>
                                  <Tooltip
                                    title="View Permission"
                                    placement="top-start"
                                  >
                                    {userType == "1" ||
                                    userActions?.includes(5) ? (
                                      <Button
                                        onClick={() => {
                                          handleOpenPermssionModal();
                                          setSelectUserid([row.id, row.type]);
                                        }}
                                      >
                                        <GroupAddIcon />
                                      </Button>
                                    ) : null}
                                  </Tooltip>
                                </>
                              )}
                            </ButtonGroup>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                {rows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 25, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      </>
      }
      {LoadingModal()}
      {AuthModal()}
      {selectedFile && (
        <ViewFile
          fileId={selectedFile.id}
          fileType={selectedFile.type}
          onClose={() => setSelectedFile(null)}
        />
      )}
    </div>
  );
}
