import { Button } from "@mui/material";
import axios from "axios";
import Image from "mui-image";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../global";

const SignatureCanvas = ({ fieldName,updateUrl, url }) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDrawing(true);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    let x, y;
    if (e.type == "mousemove") {
      x = e.clientX - rect.left;
      y = e.clientY - rect.top;
    } else if (e.type == "touchmove") {
      x = e.touches[0].clientX - rect.left;
      y = e.touches[0].clientY - rect.top;
    }
    ctx.beginPath();
    ctx.moveTo(x, y);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    let x, y;
    if (e.type == "mousemove") {
      x = e.clientX - rect.left;
      y = e.clientY - rect.top;
    } else if (e.type == "touchmove") {
      x = e.touches[0].clientX - rect.left;
      y = e.touches[0].clientY - rect.top;
    }

    ctx.lineTo(x, y);
    ctx.stroke();
  };

  const handleMouseUp = () => {
    
    setIsDrawing(false);
  };

  const handleClear = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const uploadFile = async (file) => {
    const data = new FormData();
    data.append("fileToUpload", file);

    let res = await axios.post(
      BASE_URL+`fileUpload/fileUpload.php`,
      data
    );

    if (res.status === 200) {
      return res.data;
    }
    return null;
  };

  const handleSaveImage = async () => {
    const canvas = canvasRef.current;
    const image = canvas.toDataURL("image/png");
    // You can do something with the image data, like save it to a state variable or send it to a server.
    const blob = await (await fetch(image)).blob();
    const file = new File([blob], "signature" + Date.now() + ".png", {
      type: blob.type,
    });
    const url = await uploadFile(file);
    updateUrl(url);
    toast("Signature confirmed");
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const imageUrl = await uploadFile(file);
        console.log(`Uploaded image URL for ${fieldName}:`, imageUrl);
        updateUrl(imageUrl);
        toast("Image uploaded successfully");
      } catch (error) {
        console.error("Error uploading image:", error);
        toast.error("Failed to upload image");
      }
    }
  };

  return (
    <div>
      {url != "" && isEdit === false && <Image src={url} width={130} />}
      {isEdit === true && (
        <canvas
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchMove={handleMouseMove}
          onTouchEnd={handleMouseUp}
          width={130}
          height={130}
          style={{ border: "1px solid black",  touchAction: "none" }}
        />
      )}
      <br />
      <Button
        className="hide-on-print"
        variant="outlined"
        size="small"
        onClick={() => {
          setIsEdit(!isEdit);
        }}
      >
        {isEdit === true ? "Cancel" : "Edit"}
      </Button>
      &nbsp;&nbsp;
      {isEdit === true && (
        <Button
          className="hide-on-print"
          variant="outlined"
          size="small"
          onClick={handleClear}
        >
          Clear
        </Button>
      )}
      &nbsp;&nbsp;
      <Button
        className="hide-on-print"
        variant="outlined"
        size="small"
        onClick={handleSaveImage}
      >
        Confirm
      </Button>
      &nbsp;&nbsp;
      <input
        type="file"
        id={`fileInput-${fieldName}`}
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />
      <label htmlFor={`fileInput-${fieldName}`}>
        <Button
          className="hide-on-print"
          variant="outlined"
          size="small"
          component="span"
        >
          Upload
        </Button>
      </label>
    </div>
  );
};

export default SignatureCanvas;
