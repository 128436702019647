import { configureStore } from "@reduxjs/toolkit";

import loginSlice from "../components/login/loginSlice";

// const config = {
//   // TOGGLE_TODO will not be triggered in other tabs
//   blacklist: ["TOGGLE_TODO"],
// };
// const middlewares = [createStateSyncMiddleware(config)];

export const store = configureStore({
  reducer: {
    login: loginSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(), //.concat(middlewares),
});

// initMessageListener(store);
