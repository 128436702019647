import React, { useEffect } from "react";
import { BASE_URL } from "../../global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import Toolbar from "@mui/material/Toolbar";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { TextField, Autocomplete, Table, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import "../helper/helper.css";
import { userTypes } from "./editUserMaster";

// datatablse packages
var $ = require("jquery");
$.DataTable = require("datatables.net");

const axios = require("axios");

const columns = [
  { id: "id", label: "Sr. No.", minWidth: 20 },
  { id: "userName", label: "User Name", minWidth: 40 },
  { id: "password", label: "Password", minWidth: 40 },
  { id: "type", label: "Type", minWidth: 40 },
  { id: "groupNames", label: "Group Names", minWidth: 40 },
];


export default function UserList(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [userData, setUserData] = React.useState([]);
  const [searchBy, setSearchBy] = React.useState("");
  const [searchKey, setSearchKey] = React.useState("");
  const userID = localStorage.getItem("id");
  const userType = localStorage.getItem("type");
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const submitSiteDelete = (id) => {
    let url = BASE_URL;
    axios
      .delete(url + `users/${id}`)
      .then((res) => {
        toast("User is deleted!");
        getClientList();
      })
      .catch((error) => {
        toast.error("Something Went Wrong!");
      });
  };

  const getQuery = (searchBy, searchKey) => {
    let whereConditions = "";
  
    if (searchBy !== "" && searchKey !== "") {
      let colName = {
        "User Name": "username",
        Type: "type",
        "User Group": "userGroupIds",
      }[searchBy];
  
      if (colName === "type") {
        const typeId = userTypes.find((type) =>
          type?.label?.toLowerCase().includes(searchKey.toLowerCase())
        )?.id;
  
        if (typeId) {
          whereConditions = `${colName} = ${typeId}`;
        }
      } else if (colName === "userGroupIds") {
        whereConditions = `userGroups.name LIKE '%${searchKey}%'`; // This line might need adjustment
      } else {
        whereConditions = `${colName} LIKE '%${searchKey}%'`;
      }
    }
  
    let selectClause = "users.*, GROUP_CONCAT(userGroups.id) AS userGroupIds, GROUP_CONCAT(userGroups.name) AS groupNames"; // Include userGroups in selectClause
    let joinClause = "LEFT JOIN userGroups ON FIND_IN_SET(userGroups.id, users.userGroupIds)"; // Always include userGroups in joinClause
  
    let query = "";
    if (userType == 1) {
      query = `SELECT ${selectClause} FROM users ${joinClause} WHERE users.status = 1 ${
        whereConditions !== "" ? "AND " + whereConditions : ""
      } GROUP BY users.id ORDER BY users.id DESC`;
    } else {
      query = `SELECT ${selectClause} FROM users ${joinClause} WHERE users.status = 1 AND users.createdBy = '${userID}' ${
        whereConditions !== "" ? "AND " + whereConditions : ""
      } GROUP BY users.id ORDER BY users.id DESC`;
    }
  
    let data = {
      query: query,
    };
  
    return data;
  };
  

  function getClientList() {
    let data;
    if (userType == 1) {
      data = {
        query: `SELECT u.*, GROUP_CONCAT(ug.name) AS groupNames FROM users u LEFT JOIN userGroups ug ON FIND_IN_SET(ug.id, u.userGroupIds) WHERE u.status = 1 GROUP BY u.id ORDER BY u.id DESC`,
      };
    } else {
      data = {
        query: `SELECT u.*, GROUP_CONCAT(ug.name) AS groupNames FROM users u LEFT JOIN userGroups ug ON FIND_IN_SET(ug.id, u.userGroupIds) WHERE u.status = 1 AND u.createdBy = '${userID}' GROUP BY u.id ORDER BY u.id DESC`,
      };
    }
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        let users = res.data;
        var rowData = [];
        for (let i = 0; i < users.length; i++) {
          rowData.push({
            id: users[i].id,
            userName: users[i].userName,
            password: users[i].password,
            type:
              userTypes.find((e) => e.id === users[i].type)?.label ||
              "Unknown Type",
              groupNames: users[i].groupNames,
              modules: users[i].modules,
            });
        }
        setUserData(rowData);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  function searchClients() {
    const data = getQuery(searchBy, searchKey);
    axios
      .post(BASE_URL + `dynamic`, data)
      .then((res) => {
        let users = res.data;
        var rowData = [];
        for (let i = 0; i < users.length; i++) {
          rowData.push({
            id: users[i].id,
            userName: users[i].userName,
            password: users[i].password,
            type:
              userTypes.find((e) => e.id === users[i].type)?.label ||
              "Unknown Type",
              groupNames: users[i].groupNames,
            modules: users[i].modules,
          });
        }
        setUserData(rowData);
      })
      .catch((err) => {
        console.log("clients data fetching error: ", err);
      });
  }

  useEffect(() => {
    getClientList();
  }, [rowsPerPage, page]);

  return (
    <Box sx={{ margin: "5px" }}>
      <Toolbar style={{ padding: "10px 5px" }}>
        <Button
          variant="contained"
          size="small"
          component={Link}
          to="/users/addUser"
        >
          <b>create user</b>
        </Button>
      </Toolbar>
      <Grid
        container
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
        style={{ padding: "10px 5px" }}
      >
        <Grid item xs={12} sm={3} md={2}>
          <Autocomplete
            size="small"
            id="combo-box-demo"
            value={searchBy}
            options={[
              { key: "username", label: "User Name" },
              { key: "type", label: "Type" },
              { key: "type", label: "User Group" },
            ]}
            renderInput={(params) => (
              <TextField {...params} label="Search By" />
            )}
            onInputChange={(event, newInputValue) => {
              setSearchBy(newInputValue);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <TextField
            style={{ width: "100%" }}
            id="outlined-basic"
            label={"enter " + searchBy}
            size="small"
            value={searchKey}
            variant="outlined"
            onChange={(e) => setSearchKey(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} sm={3} md={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              searchClients();
            }}
          >
            <SearchIcon />
          </Button>
        </Grid>
        <Grid item xs={6} sm={1.5} md={1} textAlign={"left"}>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              getClientList("");
              setSearchBy("");
              setSearchKey("");
            }}
          >
            <RestartAltIcon />
          </Button>
        </Grid>
      </Grid>
      <br />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table mt={5} size={"small"} width="100%">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"left"}
                    style={{ width: column.minWidth }}
                    sx={{ border: 1 }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
                <TableCell sx={{ border: 1 ,width:40 }} align={"center"}>
                  <b>Actions</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData?.length > 0 &&
                userData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.id == "id") {
                            value = page * rowsPerPage + (index + 1);
                          }
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              sx={{ border: 1 }}
                            >
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                        <TableCell
                          style={{ textAlign: "left" }}
                          sx={{ border: 1 }}
                        >
                          <ButtonGroup
                            size="small"
                            aria-label="small button group"
                            className="action-container"
                          >
                            <Tooltip title="Edit User" placement="top-start">
                              <Button
                                component={Link}
                                to={`/users/editUser/${row.id}`}
                              >
                                <PreviewIcon />
                              </Button>
                            </Tooltip>
                            <Tooltip title="Delete User" placement="top-start">
                              <Button
                                onClick={(e) => {
                                  if (
                                    window.confirm(
                                      "Really want to delete User?"
                                    )
                                  ) {
                                    submitSiteDelete(row.id);
                                  }
                                }}
                              >
                                <DeleteIcon style={{ color: "#dc3545" }} />
                              </Button>
                            </Tooltip>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            count={userData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component="div"
          />
          <ToastContainer />
        </TableContainer>
      </Paper>
    </Box>
  );
}
